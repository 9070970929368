import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getDateText,
  getDateValue,
} from "../utils";
import { fetchWeekEndDates } from "../../apis/dates";

const DateDropdown = ({ selectedDate, handleDateChange }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getDates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDates = () => {
    const currentDate = new Date();
    let startOfPreviousMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      1
    );
    let endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    fetchWeekEndDates(getDateValue(startOfPreviousMonth), getDateValue(endOfMonth)).then((response) => {
      if (response && response.data) {
        const dates = response.data;
        const options = dates.map((date) => {
          return {
            value: date,
            text: getDateText(new Date(date)),
          };
        });
        setOptions(options);
      }
    });
  };

  // TODO - Need to remove this if api flow works fine.
  // const generateOptions = () => {
  //   const today = new Date();
  //   const options = [];

  //   // Function to check if a given date is a Friday
  //   const isFriday = (date) => {
  //     return date.getDay() === 5; // 0 = Sunday, 1 = Monday, ..., 5 = Friday
  //   };
  //   let currentDate = new Date(
  //     today.getFullYear(),
  //     today.getMonth(),
  //     today.getDate()
  //   );
  //   let optionsCount = 0;
  //   //Add upcoming Friday if it is not the current date
  //   let dateToBePushed = getMonthEndDateOrUpcomingFriday();
  //   if (currentDate.getTime() !== dateToBePushed.getTime()) {
  //     const optionValue = getDateValue(dateToBePushed);
  //     const optionText = getDateText(dateToBePushed);
  //     options.push({
  //       value: optionValue,
  //       text: optionText,
  //     });
  //   }
  //   while (optionsCount < 10) {
  //     // Check if the current date is a Friday or the last day of the month
  //     if (
  //       isFriday(currentDate) ||
  //       currentDate.getDate() ===
  //         new Date(
  //           currentDate.getFullYear(),
  //           currentDate.getMonth() + 1,
  //           0
  //         ).getDate()
  //     ) {
  //       const optionValue = getDateValue(currentDate);
  //       const optionText = getDateText(currentDate);
  //       options.push({ value: optionValue, text: optionText });
  //       optionsCount++;
  //     }
  //     currentDate.setDate(currentDate.getDate() - 1);
  //   }
  //   setOptions(options);
  // };

  return (
    <FormControl sx={{ pr: 2, minWidth: 120, width: "200px" }} size="small">
      <InputLabel id="week-end-date-select-label">Week End Date</InputLabel>
      <Select
        labelId="week-end-date-select-label"
        id="week-end-date-select"
        value={selectedDate}
        label="Week End Date"
        onChange={handleDateChange}
      >
        {options.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.text}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default DateDropdown;
