import React, { useEffect, useState, useMemo, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Loader from "../../shared/loader/loader";
import styles from "./index.module.css";
import { wsrStatusRequest } from "../../actions/dashboard";
import CardDisplay from "./card-render";
import BarChartDisplay from "./bar-chart-render";
import ErrorDisplay from "./error-render";
import { cardColors } from "../../shared/utils";
import DateDisplay from "./date-render";
import {
  getDateValue,
  getMonthEndDateOrUpcomingFriday,
} from "../../shared/utils";

const Dashboard = () => {
  const [summary, setSummary] = useState([]);
  const [open, setOpen] = useState(true);

  const dispatcher = useDispatch();
  const projects = useSelector((state) => state.projects);
  const currentUser = useSelector((state) => state.users.currentUser);
  const dashboardData = useSelector((state) => state.dashboard);

  const selectedDate = useMemo(
    () => sessionStorage.getItem("dashboard_filter_date"),
    []
  );

  useEffect(() => {
    try {
      if (selectedDate) {
        dispatcher(wsrStatusRequest(selectedDate));
      } else {
        dispatcher(
          wsrStatusRequest(getDateValue(getMonthEndDateOrUpcomingFriday()))
        );
      }
    } catch (error) {
      console.warn(error.message);
    }
  }, [dispatcher, selectedDate]);

  useEffect(() => {
    if (dashboardData?.allWsrs?.length > 0 && projects?.wsrStatus?.length > 0) {
      const filteredSummary = dashboardData.allWsrs.map((element) => {
        const color = cardColors.find(
          (item) => item.id === element.wsrStatusId
        )?.color;
        const name = projects.wsrStatus.find(
          (item) => item.id === element.wsrStatusId
        )?.name;
        return {
          Id: element.wsrStatusId,
          Name: name,
          Color: color,
          Count: element.count,
        };
      });
      filteredSummary.length > 0 && setSummary(filteredSummary);
    }
  }, [dashboardData.allWsrs, projects.wsrStatus]);

  return (
    <div className={styles.container}>
      <DateDisplay />
      {dashboardData.wsrLoading ? (
       <Box className="project-details-loading">
        <Loader />
      </Box>
      ) : dashboardData?.wsrError.length > 0 ? (
        <ErrorDisplay
          error={dashboardData.wsrError}
          onClose={() => setOpen((state) => !state)}
          closeError={open}
        />
      ) : (
        <Fragment>
          <CardDisplay
            cardData={summary}
            userId={currentUser?.id}
            userRole={currentUser?.userRoles}
          />
          <BarChartDisplay chartData={summary} />
        </Fragment>
      )}
    </div>
  );
};

export default Dashboard;
