import React, { useEffect, useState } from "react";
import { Modal as AddActionItem } from "../../../shared/modal";
import { Backdrop, Box, Link, TextField, Typography } from "@mui/material";
import Loader from "../../../shared/loader/loader";
import ComboBox from "../../../shared/auto-complete/autocomplete";
import projectStyles from "../../projects/index.module.css";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { isEqual } from "lodash";
import actionItemStyles from "../index.module.css";
import { getGlobalRoles } from "../../../shared/utils";
import { connect } from "react-redux";

const AddActionItemModal = ({
  open,
  handleSave,
  handleClose,
  projectId,
  userList,
  users,
  actionItemStatuses,
  projects,
  isEdit,
  actionItemToBeUpdated,
  disableProjectName,
  disableAssignedTo,
  disableActionItemStatus,
  disableDescription,
  disabledOptionsAssignedTo,
}) => {
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [assignedTo, setAssignedTo] = useState(null);
  const [actionItemStatus, setActionItemStatus] = useState(null);
  const [description, setDescription] = useState("");
  const [comment, setComment] = useState("");
  const [commentsList, setCommentList] = useState([]);
  const [disableSavebutton, setDisableSaveButton] = useState(false);
  const userRolesOfCurrentUser = users?.userRole || [];
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState({
    selectedProjectId: false,
    assignedTo: false,
    actionItemStatus: false,
    description: false,
  });

  const handleErrorOnButtonClick = () => {
    setError({
      selectedProjectId: false,
      assignedTo: false,
      actionItemStatus: false,
      description: false,
    });
    const newErrorObj = {
      selectedProjectId: !selectedProjectId,
      assignedTo: !assignedTo,
      actionItemStatus: !actionItemStatus,
      description: !description,
    };
    setError(newErrorObj);
    return (
      newErrorObj.selectedProjectId ||
      newErrorObj.assignedTo ||
      newErrorObj.actionItemStatus ||
      newErrorObj.description
    );
  };

  const getProjectToBeUpdated = () =>
    projects.find(
      (project) =>
        project.id === actionItemToBeUpdated.wsrCirculationDetails.projectId
    );

  const getActionItemStatusToBeUpdated = () =>
    actionItemStatuses.find(
      (status) =>
        status.actionItemStatusId === actionItemToBeUpdated.actionItemStatusId
    );

  const getUsersToBeUpdated = () =>
    userList.find((user) => user.id === actionItemToBeUpdated.assigneeUserId);

  useEffect(() => {
    if (isEdit && Object.keys(actionItemToBeUpdated).length > 0) {
      const actionItemStatusSelected = getActionItemStatusToBeUpdated();
      const projectSelected = getProjectToBeUpdated();
      const userSelected = getUsersToBeUpdated();
      setSelectedProjectId(projectSelected);
      setAssignedTo(userSelected);
      setActionItemStatus(actionItemStatusSelected);
      setDescription(actionItemToBeUpdated.description);
      setCommentList(actionItemToBeUpdated.comments);
    }
    if (isEdit) {
      setDisableSaveButton(
        !(selectedProjectId && assignedTo && actionItemStatus && description)
      );
    } else {
      setDisableSaveButton(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  useEffect(() => {
    if (projectId) {
      const selectedProjectId = projects.find(
        (project) => project.id === projectId
      );
      selectedProjectId && setSelectedProjectId(selectedProjectId);
      setProjectOwnerBasedOnProject(selectedProjectId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (actionItemStatuses.length > 1 && !actionItemStatus && !isEdit) {
      setActionItemStatus(actionItemStatuses[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionItemStatuses.length]);

  useEffect(() => {
    isEdit && handleChange(commentsList, actionItemToBeUpdated.comments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentsList.length]);

  const resetModal = () => {
    setSelectedProjectId(null);
    setAssignedTo(null);
    setActionItemStatus(null);
    setDescription("");
    setComment("");
    setCommentList([]);
  };

  const onClose = (ev, reason) => {
    setInProgress(false);
    if (reason === "backdropClick") {
      return false;
    }
    resetModal();
    handleClose();
  };

  const handleChange = (newValue, initialEditValue, setValue) => {
    if (isEqual(initialEditValue, newValue)) {
      setDisableSaveButton(true);
    } else {
      setDisableSaveButton(false);
    }
    setValue && setValue();
  };

  const setProjectOwnerBasedOnProject = (project) => {
    const { projectOwnerId } = project || {};
    const projectOwner = userList.find((user) => user.id === projectOwnerId);
    if (
      users.currentUser?.id === projectOwner?.id &&
      userRolesOfCurrentUser.includes(getGlobalRoles.PO)
    ) {
      setAssignedTo(null);
    } else {
      projectOwner && setAssignedTo(projectOwner);
    }
  };

  const modalContent = () => {
    const { filter_container, link } = projectStyles;
    const { link_container } = actionItemStyles;
    return (
      <Box className={filter_container}>
          <Backdrop open={inProgress}>
            <Loader />
          </Backdrop>
        {selectedProjectId?.wsrCirculationDetails[0]?.url && (
          <Box className={link_container} mb={2}>
            <Link
              href={selectedProjectId?.wsrCirculationDetails[0]?.url}
              target="_blank"
              className={link}
              disabled={inProgress}
            >
              Open WSR
            </Link>
          </Box>
        )}
        <Box mb={2}>
          <ComboBox
            helperText={
              error.selectedProjectId &&
              "Please provide a Project Name for the action item."
            }
            error={error.selectedProjectId}
            disabled={disableProjectName || isEdit || inProgress}
            label={"Project Name"}
            data={projects || []}
            value={selectedProjectId}
            handleChange={(e, newValue) => {
              handleChange(
                newValue,
                isEdit ? getProjectToBeUpdated() : undefined,
                () => setSelectedProjectId(newValue)
              );
              setProjectOwnerBasedOnProject(newValue);
            }}
          />
        </Box>
        <Box mb={2}>
          <ComboBox
            helperText={
              error.assignedTo &&
              "Please select an assignee for the action item."
            }
            error={error.assignedTo}
            disabled={disableAssignedTo || inProgress}
            label={"Assigned To"}
            disabledOptions={disabledOptionsAssignedTo}
            data={userList || []}
            value={assignedTo}
            handleChange={(e, newValue) => {
              handleChange(
                newValue,
                isEdit ? getUsersToBeUpdated() : undefined,
                () => setAssignedTo(newValue)
              );
            }}
          />
        </Box>
        <Box mb={2}>
          <ComboBox
            helperText={
              error.actionItemStatus && "Please provide an Action Item Status."
            }
            error={error.actionItemStatus}
            disabled={disableActionItemStatus || inProgress}
            label={"Action Item Status"}
            data={actionItemStatuses || []}
            value={actionItemStatus}
            handleChange={(e, newValue) => {
              handleChange(
                newValue,
                isEdit ? getActionItemStatusToBeUpdated() : undefined,
                () => setActionItemStatus(newValue)
              );
            }}
          />
        </Box>
        <Box mb={2}>
          <TextField
            helperText={
              error.description &&
              "Please provide a description for the action item."
            }
            error={error.description}
            disabled={disableDescription || inProgress}
            multiline={true}
            rows={4}
            fullWidth={true}
            label={"Description"}
            value={description}
            onChange={(e) => {
              handleChange(
                e.target.value,
                isEdit ? actionItemToBeUpdated.description : undefined,
                () => setDescription(e.target.value)
              );
            }}
          />
        </Box>
        {isEdit && (
          <>
            <Typography mb={1}>Comments</Typography>
            <Box>
              {commentsList.map((comment, key) => {
                return (
                  <Box display={"flex"} key={key}>
                    <Typography mb={1} flex={1}>
                      {comment.description}
                    </Typography>
                    <Box flex={0.1} my={"auto"}>
                      <CloseIcon
                        onClick={() => {
                          const updatedComments = commentsList.filter(
                            (commentString) => commentString !== comment
                          );
                          setCommentList(updatedComments);
                        }}
                        className="cursor-pointer icon done-icon"
                      />
                    </Box>
                  </Box>
                );
              })}
            </Box>
            <Box mb={2} display={"flex"}>
              <Box flex={1}>
                <TextField
                  sx={{ ">p": { margin: "2px 0 0 0", lineHeight: "1.2em" } }}
                  multiline={true}
                  disabled={inProgress}
                  maxRows={2}
                  fullWidth={true}
                  helperText={
                    "Please click on the check/cross icon to add/delete the comment, then click on update to save the changes."
                  }
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                />
              </Box>
              <Box flex={0.1} my={"auto"}>
                <DoneIcon
                  onClick={() => {
                    setCommentList([...commentsList, { description: comment }]);
                    setComment("");
                  }}
                  className="cursor-pointer icon done-icon"
                />
              </Box>
            </Box>
          </>
        )}
      </Box>
    );
  };

  const addActionItem = () => {
    setInProgress(true);
    let payload = {
      wsrCirculationDetailsId: selectedProjectId?.wsrCirculationDetails[0].id,
      assigneeUserId: assignedTo?.id,
      actionItemStatusId: actionItemStatus?.actionItemStatusId,
      description,
    };
    const isErrorFound = handleErrorOnButtonClick();
    if (isErrorFound) {
      setInProgress(false);
      return;
    }

    isEdit && (payload = { ...payload, id: actionItemToBeUpdated.id });
    isEdit && (payload = { ...payload, comments: [...commentsList] });
    handleSave(payload);
  };

  return (
    <AddActionItem
      disableSaveButton={disableSavebutton}
      open={open}
      title={`${isEdit ? "Update" : "Add"} Action Item`}
      handleClose={onClose}
      inProgress={inProgress}
      handleSave={addActionItem}
      saveButtonText={isEdit ? "Update" : "Add"}
      content={modalContent()}
    />
  );
};

export default connect(({ users }) => ({
  users,
}))(AddActionItemModal);
