const pmoRequiredFields = [
    "requiredReviewerIds",
    "wsrStatus",
    "projectOwnerId",
    "sendEmailToCustomer",
    "ccEmail",
    "toEmail",
];

const poRequiredFields = [
    "sendEmailToCustomer"
];

const sendEmailToCustomerOptions = [
    {id: 1, name: 'Yes'},
    {id: 0, name: 'No'}
];

export {
    sendEmailToCustomerOptions,
    poRequiredFields,
    pmoRequiredFields
}