import BarChart from "../../shared/charts/bar-chart/BarChart";
import { Paper } from "@mui/material";

const BarChartDisplay = (props) => {
  return (
    <Paper
      elevation={4}
      sx={{
        display: { xs: "none", md: "grid" },
        placeItems: "center",
        borderRadius: "18px",
        backgroundColor: "#FEF8F6",
        padding: "10px",
        border: "1px solid #dd4b39",
      }}
    >
      <BarChart barData={props.chartData} />
    </Paper>
  );
};

export default BarChartDisplay;
