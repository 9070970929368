import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const CutomTextField = styled(TextField)({
  //Todo
  // '& label.Mui-focused': {
  //   color: "#dd4b39",
  // },
  // '& .MuiInput-underline:after': {
  //   borderBottomColor:"#dd4b39",
  // },
  // '& .MuiOutlinedInput-root': {
  //   '&:hover fieldset': {
  //     borderColor: "#dd4b39",
  //   },
  //   '&.Mui-focused fieldset': {
  //     borderColor: "#dd4b39",
  //   },
  // },
});

export default CutomTextField;