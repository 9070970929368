import { Types } from "../actions/dashboard";

const INITIAL_STATE = {
  allWsrs: [],
  wsrLoading: true,
  wsrError: "",
};

export const dashboardReducer = (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case Types.GET_WSRS_REQUEST:
      return {
        ...state,
        allWsrs: [],
        wsrLoading: true,
        wsrError: "",
      };
    case Types.GET_WSRS_SUCCESS:
      return {
        ...state,
        allWsrs: [...actions.payload.items],
        wsrLoading: false,
      };
    case Types.GET_WSRS_ERROR:
      return {
        ...state,
        wsrLoading: false,
        wsrError: actions.payload.error,
      };
    default:
      return state;
  }
};
