import { Types } from "../actions/users";

const INITIAL_STATE = {
  users: [],
  currentUser: {},
  roles: [],
  userRole:null,
  loading: true,
  currentUserLoading: true,
  currentUserError: null,
  error: "",
};

export const UsersReducer = (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case Types.GET_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        roles: actions.payload.items,
      };
    case Types.GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: actions.payload.items,
      };
    case Types.GET_CURRENT_USER_REQUEST:
      return {
        ...state,
        currentUserLoading: true,
      };
    case Types.GET_CURRENT_USER_SUCCESS: {
      let userRole = []
      if (actions.payload.user.userRoles && actions.payload.user.userRoles.length > 0) {
        userRole = actions.payload.user.userRoles.map(role => role.roleName || "");
      }
      return {
        ...state,
        currentUserLoading: false,
        // userRole:['PO','Reviewer'],
        userRole: userRole,
        currentUser: actions.payload.user,
      };
    }
    case Types.ROLES_ERROR:
    case Types.USERS_ERROR:
      return {
        ...state,
        loading: false,
        error: actions.payload.error,
      };
    case Types.CURRENT_USER_ERROR:
      return {
        currentUserLoading: false,
        currentUserError: actions.payload,
      };
    default:
      return state;
  }
};
