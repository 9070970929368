import api from "../api";

export const getActionItems = (body) => {
  return api.post("/actionItems/filter", { ...body });
};

export const getActionItemStatuses = () => {
  return api.get("/actionItems/actionItemStatuses");
};

export const addActionItems = (body) => {
  return api.post("/actionItems", { ...body });
};

export const updateActionItems = (body) => {
  return api.put("/actionItems", { ...body });
};

export const removeActionItem = (id) => {
  return api.delete(`/actionItems/${id}`);
};
