export const getDateValue = (date) => {
  return `${date.getFullYear()}-${addLeadingZero(
    date.getMonth() + 1
  )}-${addLeadingZero(date.getDate())}`;
};

export const getDateText = (date) => {
  return date.toLocaleDateString("en-IN", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const getUpcomingFriday = () => {
  const today = new Date();
  var daysUntilFriday = (5 - today.getDay() + 7) % 7;
  var upcomingFriday = new Date(
    today.getTime() + daysUntilFriday * 24 * 60 * 60 * 1000
  );
  return new Date(
    upcomingFriday.getFullYear(),
    upcomingFriday.getMonth(),
    upcomingFriday.getDate()
  );
};

export const getDateExtendedToWednesday = (selectedDate) => {
  const fridayDate = new Date(selectedDate);
  return new Date(fridayDate.setDate(fridayDate.getDate() + 5));
}

export const addLeadingZero = (num) => {
  return num < 10 ? "0" + num : num;
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { day: "numeric", month: "long", year: "numeric" };
  return date.toLocaleDateString("en-IN", options);
};

export const cardColors = [
  {
    id: 1,
    name: "Pending",
    color: "#4F709C",
  },
  {
    id: 2,
    name: "Ready For Review",
    color: "#008FB3",
  },
  {
    id: 3,
    name: "Under Review",
    color: "#A47F93",
  },
  {
    id: 4,
    name: "Action Required",
    color: "#E45858",
  },
  {
    id: 5,
    name: "Pending Approval",
    color: "#A78601",
  },
  {
    id: 6,
    name: "Reviewed/Approved",
    color: "#53976C",
  },
  {
    id: 7,
    name: "Sent",
    color: "#219C90",
  },
];

export const getGlobalRoles = Object.freeze({
  PMO: "PMO",
  PO: "PO",
  Reviewer: "Reviewer",
});

export const getCurrentMonthEndDate = () => {
  const currentDate = new Date();
  const monthLastDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );
  return monthLastDate;
};

export const getMonthEndDateOrUpcomingFriday = () => {
  let currentMonthEndDate = getCurrentMonthEndDate();
  let upcomingFriday = getUpcomingFriday();
  return currentMonthEndDate < upcomingFriday
    ? currentMonthEndDate
    : upcomingFriday;
};
