import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { connect } from "react-redux";
import Projects from "./components/projects";
import ProjectDetails from "./components/projects/project-details";
import ActionItems from "./components/action-items";
import Header from "./shared/header/header";
import Dashboard from "./components/dashboard";
import Sidebar from "./shared/navbar/navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { getCurrentUserRequest, getUsersRequest, getRolesRequest } from "./actions/users";
import { getWSRRequest } from "./actions/projects";
import Loader from "./shared/loader/loader";
import Login from "./login";

const customTheme = createTheme({
  palette: {
    themeColor: {
      main: "#dd4b39",
      contrastText: "#fff",
    },
  },
});

const drawerWidth = 150;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    color: "green",
    flexGrow: 1,
    width: "100vw",
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `10px`,
    ...(open && {
      width: "calc(100vw - 240px)!important",
      transition: theme.transitions.create("margin, width", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 50,
    }),
  })
);

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const Layout = ({ 
  getCurrentUserRequest,
  getUsersRequest, 
  getRolesRequest, 
  getWSRRequest,
  users 
}) => {

  const [open, setOpen] = React.useState(true);

  React.useEffect(() => {
    getCurrentUserRequest();
    getWSRRequest();
    getRolesRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    getUsersRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users.roles]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Router>
      {users.currentUserLoading ? (
        <Box className="app-loading">
          <Loader />
        </Box>
      ) : users.currentUserError ? (
        <Login unAuthorize = {true}/>
      ) : (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <Header
            open={open}
            handleDrawerOpen={handleDrawerOpen}
            drawerWidth={drawerWidth}
          />
          <Sidebar
            open={open}
            handleDrawerClose={handleDrawerClose}
            drawerWidth={drawerWidth}
          />
          <ThemeProvider theme={customTheme}>
            <Main open={open}>
              <DrawerHeader />
              <Routes>
                <Route exact path="/" element={<Dashboard />} />
                <Route
                  path="/projects"
                  element={
                    <Projects navOpen={open} drawerWidth={drawerWidth} />
                  }
                />
                <Route
                  path="/project-details"
                  element={<ProjectDetails />}
                />
                <Route path="/action-items" element={<ActionItems />} />
              </Routes>
            </Main>
          </ThemeProvider>
        </Box>
      )}
    </Router>
  );
};
export default connect(({ users, projects }) => ({ users, projects }), {
  getCurrentUserRequest,
  getUsersRequest, 
  getRolesRequest,
  getWSRRequest
})(Layout);
