import * as actions from "../actions/dashboard";
import { takeEvery, fork, put, call } from "redux-saga/effects";
import { getAllWsrStatus } from "../apis/dashboard";

function* getWSRS(data) {
  try {
    let result = yield call(getAllWsrStatus, data.payload);
    yield put(
      actions.wsrStatusSuccess({
        items: result?.data || [],
      })
    );
  } catch (e) {
    yield put(
      actions.wsrStatusError({
         error: (e.response && e.response?.data) || "An error occured while trying to get Dashboard data",
      })
    );
  }
}

function* watchWSRS() {
  yield takeEvery(actions.Types.GET_WSRS_REQUEST, getWSRS);
}

const dashboardSagas = [fork(watchWSRS)];

export default dashboardSagas;
