import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

export default function SearchInput({onChange, value}) {
  return (
    <OutlinedInput
      onChange={onChange}
      value={value}
      size='small'
      fullWidth
      placeholder='Search projects'
      endAdornment={
        <InputAdornment position="end">
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      }
    />
  );
}