import { ResponsiveBar } from "@nivo/bar";
import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

const BarChart = (props) => {
  const [data, setData] = useState([]);
  const [count, setcount] = useState([]);

  useEffect(() => {
    props.barData?.length > 0 && setData(props.barData);
    const checkCount =
      props.barData?.length > 0 &&
      props.barData?.reduce((total, item) => {
        return total + item.Count;
      }, 0) > 0;
    setcount(checkCount);
  }, [props.barData]);

  return data?.length > 0 && count ? (
    <Box
      sx={{
        width: { md: "90%" },
        height: { md: "400px", lg: "500px" },
      }}
    >
      <ResponsiveBar
        data={data}
        keys={["Count"]}
        indexBy="Name"
        theme={{
          text: {
            fontSize: 14,
            fill: "#847c7c",
            outlineWidth: 0,
            outlineColor: "transparent",
          },
          axis: {
            legend: {
              text: {
                fontSize: 24,
              },
            },
          },
          labels: {
            text: {
              fill: "#FEF8F6",
              fontSize: 24,
              fontWeight: 500,
            },
          },
        }}
        margin={{ top: 50, right: 250, bottom: 150, left: 100 }}
        padding={0.2}
        innerPadding={1}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: false }}
        colors={data.map((item) => item.Color)}
        colorBy="index"
        borderColor={{ theme: "background" }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 30,
          legend: "Status",
          legendPosition: "middle",
          legendOffset: 120,
          truncateTickAt: 0,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 30,
          legend: "No. of WSRs",
          legendPosition: "middle",
          legendOffset: -80,
          truncateTickAt: 0,
          format: (e) => (Math.floor(e) === e ? e : ""),
        }}
        labelSkipHeight={30}
        layers={[
          "grid",
          "axes",
          "bars",
          "markers",
          "legends",
          "annotations",
          ({ bars, labelSkipHeight }) => {
            return (
              <g>
                {bars.map(({ width, height, x, y, data }) => {
                  return data.value > 0 && height < labelSkipHeight ? (
                    <text
                      key={data.index}
                      fontSize={"24px"}
                      fill="grey"
                      transform={`translate(${x + width / 2}, ${
                        y - height - 20
                      })`}
                      textAnchor="middle"
                      dominantBaseline="central"
                    >{`${data.value ?? ""}`}</text>
                  ) : null;
                })}
              </g>
            );
          },
        ]}
        legends={[
          {
            dataFrom: "indexes", // Link legend to keys (IDs)
            anchor: "right", // Position legend
            direction: "column", // Display horizontally
            justify: false, // Align items to the left
            translateX: 180, // Adjust horizontal position
            translateY: 0, // Adjust vertical position
            itemsSpacing: 2, // Space between items
            itemWidth: 100, // Width of each item
            itemHeight: 25, // Height of each item
            symbolSize: 25, // Size of color squares
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .05)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        role="application"
        ariaLabel="WSR"
        barAriaLabel={(e) =>
          e.id + ": " + e.formattedValue + " in count: " + e.indexValue
        }
      />
    </Box>
  ) : (
    <Typography align="center">Projects not available</Typography>
  );
};

export default BarChart;
