import { Typography, Card, Stack } from "@mui/material";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { updateProjectFilter } from "../../actions/projects";

const CardDisplay = (props) => {
  const navigate = useNavigate();
  const dispatcher = useDispatch();

  const cardRedirect = (wsrStatusId) => {
    sessionStorage.setItem("page", 0);
    const weekEndDate = sessionStorage.getItem("dashboard_filter_date") || sessionStorage.getItem("weekEndDate");
    dispatcher(updateProjectFilter({ 
      role: null,
      weekEndDate: null,
      projectOwner: null,
      wsrStatusId: null,
      wsrLinkAdded: null,
      WsrReviewerFilter: false,
      pagingLimit: 10,
      pagingOffset: 0,
      isAssignedToMe: null,
    }));
    navigate(`/projects?wsrStatusId=${wsrStatusId}&weekEndDate=${weekEndDate}`);
  };

  return (
    <Stack
      sx={{ width: "100%" }}
      direction={{ xs: "column", sm: "row" }}
      mb={5}
      justifyContent={"center"}
      alignItems="center"
      spacing={{ xs: 1, lg: 3 }}
      useFlexGap
      flexWrap="wrap"
    >
      {props.cardData?.map((item) => {
        return (
          <Card
            key={item.Name}
            elevation={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: { xs: "80%", sm: 120, lg: 160 },
              height: { xs: 120, sm: 120, lg: 160 },
              borderRadius: "18px",
              backgroundColor: "#FEF8F6",
              color: "grey",
              padding: 1,
              transition: "all 0.3s linear",
              ":hover": {
                transform: "scale(1.3)",
                boxShadow: "0px 5px 15px rgba(0,0,0,.3)",
                color: `${item.Color}`,
                backgroundColor: "rgba(255, 255, 255, 0.4)",
                cursor: "pointer",
              },
            }}
            onClick={() => {
              cardRedirect(item.Id);
            }}
          >
            <Typography
              sx={{ color: "inherit", fontSize: { xs: "32px", lg: "48px" } }}
              fontWeight={700}
            >
              {item.Count}
            </Typography>
            <Typography
              sx={{
                letterSpacing: 0.2,
                fontSize: { xs: "12px", lg: "14px" },
              }}
              fontWeight={500}
            >
              {item.Name}
            </Typography>
          </Card>
        );
      })}
    </Stack>
  );
};

export default CardDisplay;
