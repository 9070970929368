export const Types = {
  GET_USERS_REQUEST: "get_users_request",
  GET_ROLES_REQUEST: "get_roles_request",
  GET_CURRENT_USER_REQUEST: "get_current_user_request",
  GET_USERS_SUCCESS: "get_users_success",
  GET_ROLES_SUCCESS: "get_roles_success",
  GET_CURRENT_USER_SUCCESS: "get_current_user_success",
  USERS_ERROR: "users_error",
  ROLES_ERROR: "role_error",
  CURRENT_USER_ERROR: "current_user_error",
};

export const getUsersRequest = (payload) => ({
  type: Types.GET_USERS_REQUEST,
  payload: payload,
});

export const getCurrentUserRequest = () => ({
  type: Types.GET_CURRENT_USER_REQUEST,
});

export const getRolesRequest = (payload) => ({
  type: Types.GET_ROLES_REQUEST,
  payload: payload,
});

export const getUsersSuccess = ({ items }) => ({
  type: Types.GET_USERS_SUCCESS,
  payload: { items },
});

export const getCurrentUserSuccess = ({ user }) => ({
    type: Types.GET_CURRENT_USER_SUCCESS,
    payload: { user },
});

export const getRolesSuccess = ({ items }) => ({
  type: Types.GET_ROLES_SUCCESS,
  payload: { items },
});

export const usersError = ({ error }) => ({
  type: Types.USERS_ERROR,
  payload: { error },
});

export const currentUserError = (payload) => ({
  type: Types.CURRENT_USER_ERROR,
  payload: payload,
});

export const rolesError = ({ error }) => ({
  type: Types.ROLES_ERROR,
  payload: { error },
});
