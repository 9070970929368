import Alert from "../../shared/notification";
import { Fragment } from "react";

const ErrorDisplay = (props) => {
  return (
    <Fragment>
      {props.error?.length > 0 && (
        <Alert
          message={props.error}
          open={props.closeError ? true : false}
          severity={"error"}
          onClose={props.onClose}
        />
      )}
    </Fragment>
  );
};

export default ErrorDisplay;
