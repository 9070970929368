import React from "react";
import App from "./App";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";
import { configureStore } from "@reduxjs/toolkit";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { reducers } from "./reducers";
import "./index.css";
import "./login.css";
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './authConfig';

const sagaMiddleware = createSagaMiddleware(rootSaga);
// const store = createStore(reducers, applyMiddleware(sagaMiddleware));
const store = configureStore({
  reducer: reducers, middleware: () => [  sagaMiddleware
  ]
})
sagaMiddleware.run(rootSaga);

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const root = createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
    <Provider store={store} >
      <App instance={msalInstance} />
    </Provider>
  </React.Fragment>
);
