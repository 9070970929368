import * as React from "react";
import { useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import ActionButton from "../../shared/header/action-button";
import ComboBox from "../../shared/auto-complete/autocomplete";
import Loader from "../../shared/loader/loader";
import _, { startCase } from "lodash";
import CustomCheckbox from "../../shared/Checkbox";
import DateDropdown from "../../shared/week-end-date-select/week-end-date-select";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MailLockIcon from "@mui/icons-material/MailLock";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import Tooltip from "@mui/material/Tooltip";
import styles from "./index.module.css";
import actionItemStyles from "../action-items/index.module.css";
import { DataGrid } from "@mui/x-data-grid";
import { connect } from "react-redux";
import {
  bulkEmailSendRequest,
  getProjectsRequest,
  getSearchProjectsRequest,
  getWSRRequest,
  updateProjectFilter,
} from "../../actions/projects";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useState } from "react";
import { Modal as FilterModal } from "../../shared/modal";
import Alert from "../../shared/notification";
import {
  formatDate,
  getDateValue,
  getMonthEndDateOrUpcomingFriday,
} from "../../shared/utils";
import { Stack } from "@mui/material";
import AddActionItemModal from "../action-items/add-action-item";
import {
  addActionItemsRequest,
  getAllProjectsRequest,
  getActionItemStatusesRequest,
  resetActionAlertRequest
} from "../../actions/action-items";
import SearchInput from "../../shared/searchInput";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function Projects({
  getProjectsRequest,
  bulkEmailSendRequest,
  getWSRRequest,
  getActionItemStatusesRequest,
  addActionItemsRequest,
  getAllProjectsRequest,
  updateProjectFilter,
  users,
  projects,
  actionItems,
  resetActionAlertRequest,
  getSearchProjectsRequest
}) {
  let navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const [openAddActionItem, setOpenAddActionItem] = useState(false);
  const [isWSRAdded, setIsWSRAdded] = useState(false);
  const [isAssignedToMe, setIsAssignedToMe] = useState(false);
  const [projectOwner, setProjectOwner] = useState(null);
  const [wsrStatus, setWSRStatus] = useState(null);
  const [errorMessage, updateErrorMessage] = useState("");
  const [successMessage, updateSuccessMessage] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [currentUserRoles, setCurrentUserRoles] = useState([]);
  const [filterCount, setFilterCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectAllProjects, setSelectAllProjects] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedProjectIdForActionItem, setSelectedProjectIdForActionItem] =
    useState(null);
  const {
    container,
    filter_row,
    notification,
    badge,
    break_word,
    link,
    no_rows_text,
    filter_container,
    grid_loader,
    search_input,
    bulk_send_email,
    disabled_bulk_email,
    table_cell_checkbox,
  } = styles;
  const { icon_style, disabled_icon_style } = actionItemStyles;
  const sortedProjectItems = projects.items?.slice()?.sort((a, b) => {
    if (a?.updatedOn > b?.updatedOn) {
      return -1;
    } else if (a?.updatedOn < b?.updatedOn) {
      return 1;
    } else {
      return 0;
    }
  });
  const openBulkAction = Boolean(anchorEl);
  const openBulkActionId = open ? "open-bulkAction" : undefined;
  useEffect(() => {
    let weekEndDate =
      params.get("weekEndDate") ||
      getDateValue(getMonthEndDateOrUpcomingFriday());
    let wsrStatusId = params.get("wsrStatusId")
      ? params.get("wsrStatusId")
      : projects.projectFilter.wsrStatusId;
    updateProjectFilter({
      weekEndDate: weekEndDate,
      wsrStatusId: wsrStatusId,
      pagingOffset: parseInt(sessionStorage.getItem("page")) || 0,
      pagingLimit: parseInt(sessionStorage.getItem("pageSize")) || 10,
    });
    setFilterState();
    setConfigFromSessionStorage();
    getActionItemStatusesRequest();
    getWSRRequest();
    return () => {
      resetActionAlertRequest();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => { 
    setFilterState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users.users]);

  useEffect(() => {
    setFilterState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects.wsrStatus]);

  useEffect(() => {
    if (users.currentUser.userRoles) {
      const roles = users.currentUser.userRoles.map((role) => role.roleName);
      setCurrentUserRoles(roles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users.currentUser.userRoles]);

  useEffect(() => {
    currentUserRoles &&
      currentUserRoles.length &&
      users?.currentUser?.id &&
      selectedDate &&
      fetchProjects(parseInt(sessionStorage.getItem("page")) || 0);
      console.log('calling fetch projects');
    // selectedDate && getAllProjectsRequest(selectedDate);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users.currentUser.id, selectedDate, currentUserRoles]);

  useEffect(() => {
    actionItems.closeAddModal && handleAddActionItemClose();
  }, [actionItems.closeAddModal]);

  useEffect(() => {
    updateErrorMessage(actionItems.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionItems.error]);

  useEffect(() => {
    if (projects.emailSendError) {
      updateErrorMessage(projects.emailSendError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects.emailSendError]);

  useEffect(() => {
    if (projects.bulkEmailSendError) {
      updateErrorMessage(projects.bulkEmailSendError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects.bulkEmailSendError]);

  useEffect(() => {
    if (projects.bulkEmailSuccessMessage) {
      updateSuccessMessage(projects.bulkEmailSuccessMessage);
      setSelectedProjects([]);
      // fetchProjects(parseInt(sessionStorage.getItem("page")) || 0, selectedDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects.bulkEmailSuccessMessage]);

  useEffect(() => {
    getFilterCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectOwner, wsrStatus, isWSRAdded, isAssignedToMe]);

  const setFilterState = () => {
    if (users.users.length > 0 && projects.projectFilter.projectOwner) {
      setProjectOwner(
        users.users.find(
          (user) => user.id === parseInt(projects.projectFilter.projectOwner)
        )
      );
    }
    if (projects.wsrStatus.length > 0 && projects.projectFilter.wsrStatusId) {
      setWSRStatus(
        projects.wsrStatus.find(
          (status) => status.id === parseInt(projects.projectFilter.wsrStatusId)
        )
      );
    }
    setIsWSRAdded(projects.projectFilter.wsrLinkAdded);
    setIsAssignedToMe(projects.projectFilter.isAssignedToMe);
  };

  const setConfigFromSessionStorage = () => {
    if (sessionStorage.getItem("weekEndDate")) {
      setSelectedDate(sessionStorage.getItem("weekEndDate"));
      updateProjectFilter({
        pagingOffset: parseInt(sessionStorage.getItem("page")) || 0,
        pagingLimit: parseInt(sessionStorage.getItem("pageSize")) || 10,
        weekEndDate: sessionStorage.getItem("weekEndDate"),
      });
    } else {
      const dateValue = getMonthEndDateOrUpcomingFriday();
      setSelectedDate(getDateValue(dateValue));
      updateProjectFilter({
        pagingOffset: parseInt(sessionStorage.getItem("page")) || 0,
        weekEndDate: getDateValue(dateValue),
        pagingLimit: parseInt(sessionStorage.getItem("pageSize")) || 10,
      });
      sessionStorage.setItem("weekEndDate", getDateValue(dateValue));
    }
  };

  const getPayloadForFetchingProjects = (
    pageNumber = 0,
    selectedWeekDate = selectedDate,
    pageSize = parseInt(sessionStorage.getItem("pageSize")) || 10
  ) => {
    let payload = projects.projectFilter;
    let modifiedPayload = _.omitBy(payload, _.isNull);
    let projectOwner =
      users.userRole?.includes("Reviewer") && users.userRole.length === 1
        ? null
        : currentUserRoles.includes("PMO")
        ? null
        : users.currentUser.id;
    updateProjectFilter({ pagingOffset: pageNumber, pagingLimit: pageSize });
    modifiedPayload.pagingOffset = pageNumber;
    modifiedPayload.weekEndDate = selectedWeekDate;
    modifiedPayload.pagingLimit = pageSize;
    modifiedPayload.WsrReviewerFilter =
      users.userRole.includes("Reviewer") || false;
    modifiedPayload.projectOwner = payload.projectOwner || projectOwner;
    return modifiedPayload;
  };

  const fetchProjects = (pageNumber = 0, selectedWeekDate = selectedDate) => {
    getProjectsRequest(
      getPayloadForFetchingProjects(pageNumber, selectedWeekDate)
    );
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (isFilterApplied) => {
    if (!isFilterApplied) {
      setFilterState();
    }
    setOpen(false);
  };

  const handleAddActionItemOpen = () => {
    setOpenAddActionItem(true);
  };

  const handleAddActionItemClose = () => {
    setOpenAddActionItem(false);
  };

  const applyFilter = () => {
    updateProjectFilter(
      {
        projectOwner: (projectOwner && projectOwner.id) || null,
        wsrStatusId: (wsrStatus && wsrStatus.id) || null,
        wsrLinkAdded: isWSRAdded,
        isAssignedToMe: isAssignedToMe,
      },
      (payload) => {
        getProjectsRequest({
          ...projects.projectFilter,
          ...payload,
          pagingOffset: 0,
        });
        setParams({});
        handleClose(true);
        getFilterCount();
      }
    );
  };

  const handleCheckboxChange = (event, name) => {
    if (name === "isWSRAdded") {
      setIsWSRAdded(event.target.checked);
      updateProjectFilter({ wsrLinkAdded: event.target.checked });
    } else {
      setIsAssignedToMe(event.target.checked);
      updateProjectFilter({ isAssignedToMe: event.target.checked });
    }
  };

  const submitForm = (actionItemPayload) => {
    addActionItemsRequest(
      actionItemPayload,
      getPayloadForFetchingProjects(),
      true
    );
  };

  const filterContent = () => {
    const projectOwnerList = [...users.users];
    const wsrStatusList = [...projects.wsrStatus];
    return (
      <Box className={filter_container}>
        <Box mb={2}>
          <ComboBox
            label={"Project Owners"}
            data={
              projectOwnerList.filter((user) =>
                user.userRoles.find((role) => role.roleName === "PO")
              ) || []
            }
            value={projectOwner}
            handleChange={(e, newValue) => {
              setProjectOwner(newValue);
              updateProjectFilter({ projectOwner: newValue.id });
            }}
          />
        </Box>
        <Box mb={2}>
          <ComboBox
            label={"WSR Status"}
            data={wsrStatusList || []}
            value={wsrStatus}
            handleChange={(e, newValue) => {
              setWSRStatus(newValue);
              updateProjectFilter({ wsrStatusId: newValue.id });
            }}
          />
        </Box>
        <Box>
          <CustomCheckbox
            checked={isWSRAdded}
            handleChange={(event) => handleCheckboxChange(event, "isWSRAdded")}
            label="WSR Link Added"
          />
        </Box>
        <Box>
          <CustomCheckbox
            checked={isAssignedToMe}
            handleChange={(event) =>
              handleCheckboxChange(event, "isAssignedToMe")
            }
            label="Assigned To Me"
          />
        </Box>
      </Box>
    );
  };

  const clearFilters = () => {
    setProjectOwner(null);
    setWSRStatus(null);
    setIsWSRAdded(false);
    setIsAssignedToMe(false);
  };

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    setSelectedDate(selectedDate);
    sessionStorage.setItem("weekEndDate", selectedDate);
  };

  const sendEmailToCustomer = (event, row) => {
    event.preventDefault();
    event.stopPropagation();
    const payload = {
      projectIds: [row.id],
      weekEndDate: selectedDate,
    }
    const projectPayload = getPayloadForFetchingProjects(0, selectedDate);
    bulkEmailSendRequest(payload, projectPayload);
  };

  const onRowClick = (rowData) => {
    navigate(
      `/project-details?projectId=${rowData.id}&wsrId=${rowData.row.wsrCirculationDetailsId}`,
      { state: rowData.row }
    );
  };

  const handleSelectProject = (event, key) => {
    if (key === "selectAll") {
      if (event.target.checked) {
        const projectIds = [];
        sortedProjectItems.forEach((project) => {
          project.wsrStatus === "Reviewed/Approved" &&
            projectIds.push(project.id);
        });
        setSelectedProjects(projectIds);
        setSelectAllProjects(true);
      } else {
        setSelectedProjects([]);
        setSelectAllProjects(false);
      }
    } else {
      if (event.target.checked) {
        setSelectedProjects([...selectedProjects, key.row.id]);
      } else {
        const projectIds = selectedProjects.filter(
          (projectId) => projectId !== key.row.id
        );
        setSelectedProjects(projectIds);
      }
    }
  };

  const validateProjectStatus = (row) => {
    return !(
      row.wsrStatus === "Reviewed/Approved" && currentUserRoles.includes("PMO") && row.sendEmailToCustomer
    );
  };

  const columns = [
    {
      field: "Select",
      sortable: false,
      description: "Select All Projects",
      headerName: (
        <div className={table_cell_checkbox}>
          <CustomCheckbox
            disabled={!currentUserRoles.includes("PMO")}
            checked={
              selectAllProjects ||
              (selectedProjects.length === sortedProjectItems.length && selectedProjects.length > 0)
            }
            handleChange={(event) => handleSelectProject(event, "selectAll")}
          />
        </div>
      ),
      flex: 0.25,
      renderCell: (params) => (
        <div
          className={table_cell_checkbox}
          onClick={(event) => event.stopPropagation()}
        >
          <CustomCheckbox
            checked={selectedProjects.includes(params.row.id)}
            disabled={validateProjectStatus(params.row)}
            handleChange={(event) => {
              event.stopPropagation();
              handleSelectProject(event, params);
            }}
          />
        </div>
      ),
    },
    {
      field: "name",
      headerName: "Project Name",
      type: "string",
      flex: 1,
      renderCell: (params) => <div className={break_word}>{params.value}</div>,
    },
    {
      field: "wsrStatus",
      headerName: "WSR Status",
      type: "string",
      flex: 0.5,
    },
    {
      field: "projectStatus",
      headerName: "Project Status",
      type: "string",
      flex: 0.5,
    },
    {
      field: "wsrLink",
      headerName: "WSR Link",
      type: "link",
      renderCell: (params) =>
        params.value ? (
          <a
            href={params.value}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            Click Here
          </a>
        ) : (
          <center>-</center>
        ),
    },
    {
      field: "projectOwner",
      headerName: "Project Owner",
      type: "text",
      flex: 0.5,
      valueGetter: (params) => startCase(params.value),
    },
    {
      field: "updatedOn",
      headerName: "Updated On",
      type: "string",
      flex: 0.5,
      valueGetter: (params) => formatDate(params.value),
    },
    {
      field: "lastReviewedBy",
      headerName: "Last Reviewed By",
      type: "string",
      flex: 0.6,
      renderCell: (params) => <div className={break_word}>{params.value}</div>,
    },
    {
      field: "actionItems",
      headerName: "Action Items",
      align: "center",
      renderCell: (params) => (
        <a
          href={params.value}
          className={link}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            navigate(
              `/action-items?wsrId=${params.row.wsrCirculationDetailsId}&weekEndDate=${selectedDate}&projectId=${params.row.id}`
            );
          }}
        >
          {params.value}
        </a>
      ),
    },
    {
      field: "assignedTo",
      headerName: "Assigned To",
      type: "string",
      flex: 0.5,
      valueGetter: (params) => startCase(params.value),
    },
    {
      field: "sendEmailToCustomer",
      headerName: "Send To Client",
      flex: 0.3,
      renderCell: (params) =>
        params.row.wsrStatus === "Sent To Clients" ? (
          <Box>
            <Tooltip
              title="Wsr has been sent to the customer"
              placement="top-start"
              arrow
            >
              <MarkEmailReadIcon
                style={{ fill: "#dd4b39" }}
                onClick={(event) => event.stopPropagation()}
              />
            </Tooltip>
          </Box>
        ) : params.value && params.row.wsrStatus === "Reviewed/Approved" ? (
          <Box>
            <Tooltip title="Wsr is ready to sent" placement="top-start" arrow>
              {currentUserRoles.includes("PMO") ? (
                <ForwardToInboxIcon
                  onClick={(event) => sendEmailToCustomer(event, params.row)}
                />
              ) : (
                <ForwardToInboxIcon
                  onClick={(event) => event.stopPropagation()}
                  style={{ fill: "#a9a9a9" }}
                />
              )}
            </Tooltip>
          </Box>
        ) : (
          <Box>
            <Tooltip
              title={
                params.value
                  ? "Wsr status is not yet Reviewed/approved"
                  : "Email Send to Customer flag is disabled"
              }
              placement="top-start"
              arrow
            >
              <MailLockIcon
                onClick={(event) => event.stopPropagation()}
                style={{ fill: "#a9a9a9" }}
              />
            </Tooltip>
          </Box>
        ),
    },
    {
      field: "Actions",
      headerName: "Actions",
      sortable: false,
      flex: 0.3,
      renderCell: (params) => (
        <Box>
          <Tooltip
            // title={
            //   selectedDate !== getDateValue(getMonthEndDateOrUpcomingFriday())
            //     ? "Can't create Actions for Past week"
            //     : ""
            // }
            placement="top-start"
            arrow
          >
            <AddCircleIcon
              // className={
              //   selectedDate !== getDateValue(getMonthEndDateOrUpcomingFriday())
              //     ? disabled_icon_style
              //     : icon_style
              // }
              className={icon_style}
              onClick={(event) => {
                event.stopPropagation();
                // if (
                //   selectedDate !==
                //   getDateValue(getMonthEndDateOrUpcomingFriday())
                // ) {
                //   return;
                // }
                setSelectedProjectIdForActionItem(params.row.id);
                handleAddActionItemOpen();
              }}
            />
          </Tooltip>
        </Box>
      ),
    },
  ];

  const getFilterCount = () => {
    let count = 0;
    if (projectOwner) count++;
    if (wsrStatus) count++;
    if (isWSRAdded) count++;
    if (isAssignedToMe) count++;
    setFilterCount(count);
  };

  const onSerachChange = (event) => {
    setSearchText(event.target.value);
    const payload = getPayloadForFetchingProjects();
    payload["projectName"] = event.target.value;
    getSearchProjectsRequest(payload);
  };

  const handleOpenBulkActions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseBulkActions = () => {
    setAnchorEl(null);
  };

  const sendBulkEmails = () => {
    if(selectedProjects.length > 0){
      const payload = {
        projectIds: selectedProjects,
        weekEndDate: selectedDate,
      }
      bulkEmailSendRequest(payload);
    }
  }
  return (
    <Box className={container}>
      {errorMessage.length > 0 ? (
        <Box mb={2}>
          <Alert
            message={errorMessage}
            open={true}
            severity="error"
            onClose={() => updateErrorMessage("")}
          />
        </Box>
      ) : null}
      {successMessage.length > 0 ? (
        <Box mb={2}>
          <Alert
            message={successMessage}
            open={true}
            severity="success"
            onClose={() => updateSuccessMessage("")}
          />
        </Box>
      ) : null}
      <Box className={filter_row} mb={2}>
        <Box className={search_input}>
          <SearchInput onChange={onSerachChange} value={searchText} />
        </Box>
        <DateDropdown
          handleDateChange={handleDateChange}
          selectedDate={selectedDate}
        />
        <Box className={notification}>
          <ActionButton buttonText={"Filter"} onClick={handleOpen} />
          {filterCount > 0 ? (
            <Box className={badge}> {filterCount} </Box>
          ) : null}
        </Box>
        <Box className={notification} ml={2}>
          <ActionButton
            aria-describedby={openBulkActionId}
            endIcon={<KeyboardArrowDownIcon/>}
            buttonText={"Bulk Actions"}
            onClick={handleOpenBulkActions}
          />
          <Popover
            id={openBulkActionId}
            sx={{marginTop: "4px"}}
            open={openBulkAction}
            anchorEl={anchorEl}
            onClose={handleCloseBulkActions}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Box p={1}>
              <Box
                className={
                  selectedProjects.length > 0
                    ? bulk_send_email
                    : disabled_bulk_email
                }
                onClick={sendBulkEmails}
              >
                Send Email to Customers
              </Box>
            </Box>
          </Popover>
        </Box>
      </Box>
      <DataGrid loading={projects.loading}
        className="data-grid"
        rows={sortedProjectItems}
        columnHeaderHeight={35}
        density="comfortable"
        onRowClick={onRowClick}
        columns={columns}
        disableColumnMenu={true}
        pageSizeOptions={[10, 25, 50]}
        disableExport={true}
        disableReorder={true}
        filterable={false}
        resizable={false}
        slots={{
          loadingOverlay: () => (
            <Stack className={grid_loader}>
              <Loader />
            </Stack>
          ),
          noRowsOverlay: () => (
            <Stack className={no_rows_text}>No Projects</Stack>
          ),
        }}
        paginationMode="server"
        paginationModel={{
          pageSize: projects.projectFilter.pagingLimit,
          page: projects.projectFilter.pagingOffset,
        }}
        rowCount={projects.totalProjectCount}
        onPaginationModelChange={({ page, pageSize }) => {
          sessionStorage.setItem("page", page);
          sessionStorage.setItem("pageSize", pageSize);
          fetchProjects(page);
        }}
      />
      <FilterModal
        open={open}
        title="Filter Projects"
        handleClose={() => handleClose(false)}
        handleSave={applyFilter}
        saveButtonText="Apply"
        content={filterContent()}
        onClearFilter={clearFilters}
      />
      {openAddActionItem && (
        <AddActionItemModal
          open={openAddActionItem}
          handleClose={handleAddActionItemClose}
          handleSave={submitForm}
          userList={users.users}
          actionItemStatuses={actionItems.actionItemStatuses}
          disabledOptionsAssignedTo={[users.currentUser]}
          projects={actionItems.projects}
          projectId={selectedProjectIdForActionItem}
          disableActionItemStatus={true}
          disableProjectName={true}
        />
      )}
    </Box>
  );
}

export default connect(
  ({ projects, users, actionItems }) => ({ projects, users, actionItems }),
  {
    getProjectsRequest,
    bulkEmailSendRequest,
    getWSRRequest,
    addActionItemsRequest,
    getAllProjectsRequest,
    getActionItemStatusesRequest,
    resetActionAlertRequest,
    updateProjectFilter,
    getSearchProjectsRequest,
  }
)(Projects);
