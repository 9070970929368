export const Types = {
  GET_WSRS_REQUEST: "get_wsrs_request",
  GET_WSRS_SUCCESS: "get_wsrs_success",
  GET_WSRS_ERROR: "get_wsrs_error",
};

export const wsrStatusRequest = (payload) => {
  return {
    type: Types.GET_WSRS_REQUEST,
    payload,
  };
};
export const wsrStatusSuccess = ({ items }) => {
  return {
    type: Types.GET_WSRS_SUCCESS,
    payload: { items },
  };
};
export const wsrStatusError = ({ error }) => {
  return {
    type: Types.GET_WSRS_ERROR,
    payload: { error },
  };
};
