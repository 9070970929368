import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ActionButton from "../header/action-button";
import { Divider } from "@mui/material";
import { isFunction } from "lodash";

export const Modal = ({
  saveButtonText,
  handleSave,
  handleClose,
  open,
  title,
  content,
  button,
  onClearFilter,
  disableSaveButton,
  buttonPosition = "right",
  inProgress
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle textAlign={"center"}>{title}</DialogTitle>
      <Divider />
      <DialogContent>{content}</DialogContent>
      <DialogActions className={buttonPosition}>
        {button && button}
        <ActionButton
          buttonText={"Cancel"}
          onClick={handleClose}
          disabled={inProgress}
          isAlternateButton={true}
        />
        {isFunction(onClearFilter) && (
          <ActionButton
            onClick={onClearFilter}
            disabled={inProgress}
            buttonText={"Clear Filters"}
            isAlternateButton={true}
          />
        )}
        <ActionButton
          disabled={disableSaveButton || inProgress}
          onClick={handleSave}
          buttonText={saveButtonText || "Save"}
        />
      </DialogActions>
    </Dialog>
  );
};
