import { useEffect, useState } from "react";
import DateDropdown from "../../shared/week-end-date-select/week-end-date-select";
import {
  getDateValue,
  getMonthEndDateOrUpcomingFriday,
} from "../../shared/utils";
import { useDispatch } from "react-redux";
import { wsrStatusRequest } from "../../actions/dashboard";
import { Box } from "@mui/material";

const DateDisplay = () => {
  const [selectedDate, setSelectedDate] = useState("");
  const dispatcher = useDispatch();

  useEffect(() => {
    if (sessionStorage.getItem("dashboard_filter_date")) {
      setSelectedDate(sessionStorage.getItem("dashboard_filter_date"));
    } else {
      setSelectedDate(getDateValue(getMonthEndDateOrUpcomingFriday()));
    }
  }, []);

  const handleDateChange = (event) => {
    setSelectedDate(null);
    const targetDate = event.target.value;
    setSelectedDate(targetDate);
    dispatcher(wsrStatusRequest(targetDate));
    sessionStorage.setItem("dashboard_filter_date", targetDate);
  };

  return (
    <Box
      width={"100%"}
      display={"flex"}
      justifyContent={{ xs: "center", md: "flex-end" }}
      mb={4}
    >
      <DateDropdown
        handleDateChange={handleDateChange}
        selectedDate={selectedDate}
      />
    </Box>
  );
};

export default DateDisplay;
