import { combineReducers } from "redux";
import { ProjectsReducer } from "./projects";
import { UsersReducer } from "./users";
import { ActionItemsReducer } from "./action-items";
import { dashboardReducer } from "./dashboard";

export const reducers = combineReducers({
  actionItems: ActionItemsReducer,
  projects: ProjectsReducer,
  users: UsersReducer,
  dashboard: dashboardReducer
});
