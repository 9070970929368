import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

const optionDisabled = (option, disabledOptions, limit, selectedOptions) => {
  const disabledIds = disabledOptions.map( option => option.id );
  if(disabledIds.includes(option.id)) return true
  else if(!disabledIds.includes(option.id) && limit === 'max') return false
  else if(!disabledIds.includes(option.id) && selectedOptions.length >= limit) return true
  else return false
}

export default function ComboBox({ 
  error = false,
  helperText = '',
  disabled = false,
  label, 
  data, 
  value, 
  handleChange, 
  size="normal", 
  multiple = false,
  limit = 'max',
  disabledOptions = [],
  limitTags = 20
}) {
  return (
    <FormControl error={error} fullWidth>
      <Autocomplete
        multiple={multiple}
        disabled={disabled}
        id={label?.toLowerCase()}
        getOptionLabel={(option) => (option && option.name) || ""}
        getOptionDisabled={(option) => optionDisabled (option, disabledOptions, limit, value)}
        isOptionEqualToValue={(option, value) => value && option && option.id === value.id }
        options={data}
        limitTags={limitTags}
        renderInput={(params) => <TextField error={error} {...params} label={label} size={size} />}
        value={value}
        onChange={handleChange}
      />
      {error? <FormHelperText>{helperText}</FormHelperText>:''}
    </FormControl>
   
  );
}
