import api from "../api";

export const getProjectList = (body) => {
  return api.post("/projects", { ...body });
};

export const getAllProjects = (selectedDate) => {
  return api.get(`/projects/${selectedDate}`);
};

export const getWsrStatus = () => {
  return api.get("/wsrStatus");
};

export const updateWsrLink = (body) => {
  return api.put("/wsrCirculationDetails", { ...body });
};

export const getProjectDetails = (req) => {
  return api.get(`/projects/${req.id}/${req.wsrId}`);
};

export const updateProjectDetailsApi = (body) => {
  return api.put("/projects/", { ...body });
};

export const sendEmail = (body) => {
  return api.post(`/projects/${body.projectId}/${body.weekEndDate}/sendEmail`);
};

export const bulkSendEmail = (body) => {
  return api.post(`/projects/sendEmailToCustomer`,{...body});
};