import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import { InputLabel, Box } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";

import config from "../../../config";
import ComboBox from "../../../shared/auto-complete/autocomplete";
import CustomTextField from "../../../shared/input";
import ActionButton from "../../../shared/header/action-button";
import Alert from "../../../shared/notification";
import Loader from "../../../shared/loader/loader";
import { Modal as FilterModal } from "../../../shared/modal";
import {
  pmoRequiredFields,
  poRequiredFields,
  sendEmailToCustomerOptions,
} from "../../../shared/constants";

import {
  getDateValue,
  getGlobalRoles,
  getMonthEndDateOrUpcomingFriday,
  getDateExtendedToWednesday,
} from "../../../shared/utils";

import {
  getProjectDetailsRequest,
  updateProjectDetailsRequest,
  updateWsrLinkRequest,
  resetAlertRequest,
} from "../../../actions/projects";
import "./index.css";

function ProjectDetails({
  getProjectDetailsRequest,
  updateProjectDetailsRequest,
  updateWsrLinkRequest,
  resetAlertRequest,
  projects,
  users,
}) {
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const wsrId = searchParams.get("wsrId");
  const projectId = searchParams.get("projectId");
  const [detailPayload, setDetailPayload] = useState(projects.projectDetails);
  const [alert, setAlert] = useState("");
  const [wsrLink, setWsrLink] = useState("");
  const [alertType, setAlertType] = useState("");
  const [wsrStatus, setWsrStatus] = useState(null);
  const [emailsWithError, setEmailsWithError] = useState({});
  const [toEmail, setToEmail] = useState("");
  const [ccEmail, setCcEmail] = useState("");
  const [projectOwnerName, setProjectOwnerName] = useState({});
  const [sendEmailToCustomer, setSendEmailtoCustomer] = useState({});
  const [errors, setErrors] = useState([]);
  const [addWsrModalVisibility, setAddWsrModalVisibility] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [isReviewerAdded, setIsReviewerAdded] = useState(false);
  const wsrStatusList = useMemo(
    () => [...projects.wsrStatus],
    [projects.wsrStatus]
  );
  const reviewers = useMemo(() => [...users.users], [users]);

  const disabledOptionsReviewer = (selectedInOtherOptions) => {
    const selectedReviewers = [...selectedInOtherOptions];
    selectedReviewers.push(users.currentUser);
    if (projectOwnerName && Object.keys(projectOwnerName).length > 0) {
      selectedReviewers.push(projectOwnerName);
    }
    return selectedReviewers;
  };

  const disableInputForSpecificRoles = (disableOptionsList) => {
    let currentUserRoleCheck = disableOptionsList.some((elem) =>
      users?.userRole.includes(elem)
    );
    return currentUserRoleCheck || false;
  };

  const wsrEnabledforRequiredReviewer = () => {
    const requiredReviewersIds =
      detailPayload?.requiredReviewerIds?.map((rev) => rev.id) || [];
    if (users?.userRole.includes(getGlobalRoles.Reviewer)) {
      return !requiredReviewersIds.includes(users?.currentUser.id);
    } else return true;
  };

  const disabledOptionsForprojectOwner = () => {
    let selectedOptions = [];
    if (Object.keys(detailPayload).length > 0) {
      const selectedrequiredReviewerIds = [
        ...detailPayload.requiredReviewerIds,
      ];
      const selectedOptionalReviewerIds = [
        ...detailPayload.optionalReviewerIds,
      ];
      selectedOptions = selectedrequiredReviewerIds.concat(
        selectedOptionalReviewerIds
      );
    }
    return selectedOptions;
  };

  const setConfigFromSessionStorage = () => {
    if (sessionStorage.getItem("weekEndDate")) {
      setSelectedDate(sessionStorage.getItem("weekEndDate"));
    } else {
      setSelectedDate(getDateValue(getMonthEndDateOrUpcomingFriday()));
      sessionStorage.setItem(
        "weekEndDate",
        getDateValue(getMonthEndDateOrUpcomingFriday())
      );
    }
  };

  useEffect(() => {
    const payload = { id: projectId, wsrId: wsrId };
    getProjectDetailsRequest(payload);
    return () => {
      resetAlertRequest();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { projectDetails } = projects;
    if (Object.keys(projectDetails).length > 0) {
      setDetailPayload(projectDetails);
      setWsrLink(projectDetails.wsrLink);
      setCcEmail(projectDetails.ccEmail.join(","));
      setToEmail(projectDetails.toEmail.join(","));
      setConfigFromSessionStorage();
      if (reviewers.length > 0) {
        const selectedProjectOwner = reviewers.find(
          (user) => user.id === projectDetails.projectOwnerId
        );
        const selectedEmailSendOption = sendEmailToCustomerOptions.find(
          (opt) => opt.id === (projectDetails?.sendEmailToCustomer ? 1 : 0)
        );
        const requiredReviewers = reviewers.filter((user) =>
          projectDetails.requiredReviewerIds?.includes(user.id)
        );
        const optionalReviewers = reviewers.filter((user) =>
          projectDetails.optionalReviewerIds?.includes(user.id)
        );
        setDetailPayload({
          ...projectDetails,
          optionalReviewerIds: optionalReviewers,
          requiredReviewerIds: requiredReviewers,
        });
        setIsReviewerAdded(requiredReviewers.length > 0);
        setProjectOwnerName(selectedProjectOwner);
        setSendEmailtoCustomer(selectedEmailSendOption);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects.projectDetails, reviewers]);

  useEffect(() => {
    if (detailPayload?.wsrStatus && wsrStatusList.length > 0) {
      setWsrStatus(
        wsrStatusList.filter(
          (status) => status.id === detailPayload.wsrStatus
        )[0]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailPayload, wsrStatusList]);

  useEffect(() => {
    if (projects.projectDetailsError) {
      updateAlert(projects.projectDetailsError, "error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects.projectDetailsError]);

  useEffect(() => {
    if (projects.projectDetailsUpdateError) {
      updateAlert(projects.projectDetailsUpdateError, "error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects.projectDetailsUpdateError]);

  useEffect(() => {
    updateAlert(projects.wsrUploadingError, "error");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects.wsrUploadingError, projects.wsrUploading]);

  useEffect(() => {
    if (projects.wsrUploadSuccess) {
      const payload = { id: projectId, wsrId: wsrId };
      getProjectDetailsRequest(payload);
      setAddWsrModalVisibility(false);
      updateAlert("Wsr Link saved Successfully", "success");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects.wsrUploadSuccess, projects.wsrUploading]);

  useEffect(() => {
    if (projects.projectDetailsUpdateSuccess) {
      updateAlert("Project Detail update successfully", "success");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    projects.projectDetailsUpdateLoading,
    projects.projectDetailsUpdateSuccess,
  ]);

  const updateAlert = (error, type) => {
    setAlert(error);
    setAlertType(type);
  };

  const onUploadWsr = () => {
    const link = wsrLink.trim();
    const validDomain = config[process.env.REACT_APP_ENV].wsrValidDomain;
    if (link.length > 0) {
      const urlRegex =
        /(https:\/\/)([\w\-])+\.{1}([a-zA-Z]{2,63})([\/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/g;
      const isValid = link.match(urlRegex);
      const urlObject = isValid && new URL(link);
      if (isValid && urlObject && urlObject.origin === validDomain) {
        const payload = {
          url: link,
          wsrCirculationDetailsId: wsrId,
          projectId: projectId,
        };
        updateWsrLinkRequest(payload);
      } else {
        setErrors(["wsrLink"]);
      }
    } else {
      setErrors(["wsrLink"]);
    }
  };

  const onCloseFilterModal = () => {
    setAddWsrModalVisibility(false);
    setWsrLink(detailPayload?.wsrLink || "");
    removeErrorFromList("wsrLink");
  };

  const removeErrorFromList = (field) => {
    const existingErrors = errors;
    if (existingErrors.includes(field)) {
      const emailsError = emailsWithError;
      const otherEmailField =
        field === "ccEmail"
          ? "toEmail"
          : field === "toEmail"
          ? "ccEmail"
          : null;
      emailsError[field] = "";
      if (otherEmailField && emailsError[field + "_exist"]) {
        emailsError[otherEmailField + "_exist"] = "";
        emailsError[field + "_exist"] = "";
        const index = existingErrors.indexOf(otherEmailField);
        existingErrors.splice(index, 1);
      }
      const index = existingErrors.indexOf(field);
      existingErrors.splice(index, 1);
      setErrors(existingErrors);
      setEmailsWithError(emailsError);
    }
  };

  const onChangeField = (value, field) => {
    const payload = detailPayload;
    payload[field] =
      field === "ccEmail" || field === "toEmail"
        ? value
            .split(",")
            .filter((email) => email.trim() && email.trim() !== "")
        : value;
    setDetailPayload({
      ...payload,
    });
    setSaveButtonDisabled(false);
    removeErrorFromList(field);
  };

  const validatefield = (field) => {
    let isValid = true;
    switch (field) {
      case "toEmail":
      case "ccEmail": {
        const emailsError = emailsWithError;
        const checkWithField = field === "toEmail" ? "ccEmail" : "toEmail";
        emailsError[field] = "";
        const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g;
        if (!detailPayload[field] || detailPayload[field].length === 0) {
          isValid = false;
        } else {
          detailPayload[field].forEach((email) => {
            const isValidEmail = email.trim().match(emailRegex);
            if (!isValidEmail) {
              emailsError[field] = emailsError[field]
                ? emailsError[field].concat(email)
                : email;
            } else if (detailPayload[checkWithField].includes(email)) {
              emailsError[field + "_exist"] = emailsError[field + "exist"]
                ? emailsError[field + "_exist"].concat(email)
                : email;
            }
          });
          setEmailsWithError(emailsError);
          return isValid && emailsError[field]
            ? emailsError[field].length === 0
            : isValid && emailsError[field + "_exist"]
            ? emailsError[field + "_exist"].length === 0
            : true;
        }
        return isValid;
      }
      case "wsrStatus":
      case "wsrLink":
      case "sendEmailToCustomer":
      case "projectOwnerId": {
        if (!(field in detailPayload) || detailPayload[field] === "") {
          isValid = false;
          return isValid;
        } else return isValid;
      }
      case "requiredReviewerIds": {
        if (!detailPayload[field] || detailPayload[field].length === 0) {
          isValid = false;
          return isValid;
        } else return isValid;
      }
      default: {
        isValid = true;
        return isValid;
      }
    }
    /*eslint no-useless-escape: "off"*/
  };

  const onSave = () => {
    let errors = [];
    const requiredFields = users.userRole.includes("PMO")
      ? pmoRequiredFields
      : poRequiredFields;
    requiredFields.forEach((field) => {
      if (!validatefield(field)) {
        errors.push(field);
      }
    });
    if (errors.length > 0) {
      setErrors(errors);
      return;
    } else {
      if (!projectId) {
        updateAlert(
          "An error occured while trying to update the project details",
          "error"
        );
        return;
      }
      const projectDetail = Object.assign({}, detailPayload);
      const requiredReviewerIds = projectDetail.requiredReviewerIds.map(
        (rev) => rev.id
      );
      const optionalReviewerIds = projectDetail.optionalReviewerIds.map(
        (rev) => rev.id
      );
      const payload = {
        requiredReviewerIds,
        optionalReviewerIds,
        toEmail: projectDetail.toEmail,
        ccEmail: projectDetail.ccEmail,
        id: projectDetail.id,
        wsrStatus: projectDetail.wsrStatus,
        projectOwnerId: projectDetail.projectOwnerId,
        sendEmailToCustomer: projectDetail.sendEmailToCustomer,
        wsrId,
        navigateOnSuccess: () => navigate("/projects"),
      };
      updateProjectDetailsRequest(payload);
    }
  };

  const wsrContent = () => {
    return (
      <Box className="project-details-form-field filter-container">
        <Box className="label-content">
          <InputLabel
            required
            className={
              errors.includes("wsrLink") ? "input-label error" : "input-label"
            }
            size="normal"
          >
            Wsr Link
          </InputLabel>
        </Box>
        <Box className="wsr-input">
          <CustomTextField
            required
            className="w-100"
            id="wsrLink"
            autoComplete="new-password"
            error={errors.includes("wsrLink")}
            helperText={
              errors.includes("wsrLink") ? "Please add valid wsr Link" : ""
            }
            value={wsrLink}
            onChange={(e) => {
              setWsrLink(e.target.value);
              removeErrorFromList("wsrLink");
            }}
          />
        </Box>
      </Box>
    );
  };

  const isLoggedInUserNotProjectOwner = () => {
    return users.userRole.includes("PMO")
      ? false
      : users?.currentUser.id !== detailPayload?.projectOwnerId;
  };

  const disabledWsrButton = () => {
    const today = new Date(getDateValue(new Date()));
    const extendToMondayDate = new Date(getDateValue(getDateExtendedToWednesday(selectedDate)));
    const isValidWeek = extendToMondayDate >= today || selectedDate === getDateValue(getMonthEndDateOrUpcomingFriday()) ;
    let message = '';
    message = isLoggedInUserNotProjectOwner() ? 'you are not project owner of this project':
      !isValidWeek ? 'You can only update project details for current week':
      !isReviewerAdded ? 'A required reviewer should be added before adding a WSR link': ''
  
    return {
      message,
      value: (isLoggedInUserNotProjectOwner() ||
        !isValidWeek ||
        !isReviewerAdded),
      text: detailPayload?.wsrLink?.length > 0 ? "Update Wsr Link": "Add Wsr Link"
    }
  }
  const fields = [
    {
      label: "Project Name",
      type: "input",
      id: "name",
      disabled: true,
      value: detailPayload?.name || "",
      onChange: (e) => onChangeField(e.target.value, "name"),
    },
    {
      label: "Customer Name",
      type: "input",
      id: "customerName",
      disabled: true,
      value: detailPayload?.customerName || "",
      onChange: (e) => onChangeField(e.target.value, "customerName"),
    },
    {
      label: "To Emails",
      type: "input",
      required: true,
      id: "toEmail",
      error:
        emailsWithError["toEmail"] && emailsWithError["toEmail"].length > 0
          ? "'" + emailsWithError["toEmail"] + "' not valid email."
          : emailsWithError["toEmail_exist"] &&
            emailsWithError["toEmail_exist"].length > 0
          ? "'" +
            emailsWithError["toEmail_exist"] +
            "' already exit in `cc Emails`"
          : "Please add emails seperated with comma(,)",
      disabled: disableInputForSpecificRoles([
        getGlobalRoles.PO,
        getGlobalRoles.Reviewer,
      ]),
      value: toEmail,
      onChange: (e) => {
        onChangeField(e.target.value, "toEmail");
        setToEmail(e.target.value);
      },
    },
    {
      label: "CC Emails",
      type: "input",
      required: true,
      id: "ccEmail",
      error:
        emailsWithError["ccEmail"] && emailsWithError["ccEmail"].length > 0
          ? "'" + emailsWithError["ccEmail"] + "' not valid email."
          : emailsWithError["ccEmail_exist"] &&
            emailsWithError["ccEmail_exist"].length > 0
          ? "'" +
            emailsWithError["ccEmail_exist"] +
            "' already exit in `To Emails`"
          : "Please add emails seperated with comma(,)",
      disabled: disableInputForSpecificRoles([
        getGlobalRoles.PO,
        getGlobalRoles.Reviewer,
      ]),
      value: ccEmail,
      onChange: (e) => {
        onChangeField(e.target.value, "ccEmail");
        setCcEmail(e.target.value);
      },
    },
    {
      label: "Project Owner",
      type: "select",
      required: true,
      id: "projectOwnerId",
      list:
        reviewers.filter((user) =>
          user.userRoles.find((role) => role.roleName === "PO")
        ) || [],
      error: "Please add Project owner",
      disabled: disableInputForSpecificRoles([
        getGlobalRoles.PO,
        getGlobalRoles.Reviewer,
      ]),
      disabledOptions: disabledOptionsForprojectOwner(),
      value: projectOwnerName,
      onChange: (e, newValue) => {
        setProjectOwnerName(newValue);
        onChangeField(newValue?.id || "", "projectOwnerId");
      },
    },
    {
      label: "WSR Link",
      info: "Click on Add Wsr Link Button to save the Wsr link ",
      type: "input",
      id: "wsrLinkReadOnly",
      disabled: true,
      value: detailPayload?.wsrLink || "",
    },
    {
      label: "WSR Status",
      type: "select",
      id: "wsrStatus",
      list: wsrStatusList,
      required: true,
      error: "Please Select Wsr status",
      multiple: false,
      disabled:
        disableInputForSpecificRoles([
          getGlobalRoles.PO,
          getGlobalRoles.Reviewer,
        ]) && wsrEnabledforRequiredReviewer(),
      value: wsrStatus,
      onChange: (e, newValue) => {
        setWsrStatus(newValue);
        onChangeField(newValue?.id || "", "wsrStatus");
      },
    },
    {
      label: "Optional Reviewers",
      type: "select",
      id: "optionalReviewerIds",
      info: "Maximum two Optional reviewer can be selected",
      list: reviewers.filter((user) =>
        user.userRoles.find((role) => role.roleName === "Reviewer")
      ) || [],
      multiple: true,
      disabledOptions: disabledOptionsReviewer(
        detailPayload?.requiredReviewerIds || []
      ),
      disabled: isLoggedInUserNotProjectOwner(),
      limit: 2,
      value: detailPayload?.optionalReviewerIds || [],
      onChange: (e, newValue) => onChangeField(newValue, "optionalReviewerIds"),
    },
    {
      label: "Required Reviewers",
      type: "select",
      required: true,
      id: "requiredReviewerIds",
      error: "Please Select Required reviewers",
      list: reviewers.filter((user) =>
        user.userRoles.find((role) => role.roleName === "Reviewer")
      ) || [],
      disabledOptions: disabledOptionsReviewer(
        detailPayload?.optionalReviewerIds || []
      ),
      multiple: true,
      disabled: disableInputForSpecificRoles([
        getGlobalRoles.PO,
        getGlobalRoles.Reviewer,
      ]),
      value: detailPayload?.requiredReviewerIds || [],
      onChange: (e, newValue) => onChangeField(newValue, "requiredReviewerIds"),
    },
    {
      label: "Email Send To Customer",
      type: "select",
      required: true,
      id: "sendEmailToCustomer",
      error: "Please select Email Send To Customer",
      list: sendEmailToCustomerOptions,
      disabledOptions: [],
      multiple: false,
      disabled: disableInputForSpecificRoles([
        getGlobalRoles.PO,
        getGlobalRoles.Reviewer,
      ]),
      value: sendEmailToCustomer,
      onChange: (e, newValue) => {
        let value =
          newValue && "id" in newValue
            ? newValue.id === 1
              ? true
              : false
            : "";
        onChangeField(value, "sendEmailToCustomer");
        setSendEmailtoCustomer(newValue);
      },
    },
  ];

  return projects.projectDetailsLoading ||
    projects.projectDetailsUpdateLoading ||
    projects.wsrUploading ? (
    <Box className="project-details-loading">
      <Loader />
    </Box>
  ) : (
    <Box style={{ width: "calc(100% - 1px)" }}>
      {alert.length > 0 ? (
        <Alert
          message={alert}
          open={alert.length > 0}
          severity={alertType}
          onClose={() => updateAlert("", "")}
        />
      ) : null}
      <Box className="project-details-title-container">
        <Box className="page-title">Project Details</Box>
        <Box>
          <Tooltip title={disabledWsrButton().message} placement="top-start" arrow>
            <ActionButton
              tooltip ={{title:disabledWsrButton().message, placement:"top-start", arrow:true}}
              buttonText={disabledWsrButton().text}
              className={disabledWsrButton().value ? "disabled" : ""}
              onClick={(event) => {
                !disabledWsrButton().value ? setAddWsrModalVisibility(true) : event.preventDefault();
              }}
            /> 
          </Tooltip>
          <ActionButton
            className="ml-10"
            buttonText={"Action Items"}
            onClick={() => navigate("/action-items")}
          />
        </Box>
      </Box>
      <Box className="project-details-form">
        {fields.map((field) => {
          return (
            <Box className="project-details-form-field" key={field.id}>
              <Box className="label-content">
                <InputLabel
                  className={
                    errors.includes(field.id)
                      ? "input-label error"
                      : "input-label"
                  }
                  required={field.required || false}
                  size="normal"
                >
                  {field.label}
                </InputLabel>
                {field.info ? (
                  <Tooltip title={field.info} placement="top-start" arrow>
                    <InfoIcon className="cursor-pointer icon done-icon" />
                  </Tooltip>
                ) : null}
              </Box>
              <>
                {field.type === "input" ? (
                  <Box className="wsr-input">
                    <CustomTextField
                      className={"w-100"}
                      required
                      onFocus={field.onFocus}
                      id={field.id}
                      onBlur={field.onBlur}
                      autoComplete="new-password"
                      error={errors.includes(field.id)}
                      helperText={errors.includes(field.id) ? field.error : ""}
                      disabled={field.disabled}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  </Box>
                ) : field.type === "select" ? (
                  <Box>
                    <ComboBox
                      data={field.list}
                      error={errors.includes(field.id)}
                      limit={field.limit}
                      helperText={field.error}
                      disabled={field.disabled}
                      disabledOptions={field.disabledOptions}
                      limitTags={2}
                      multiple={field.multiple}
                      value={field.value}
                      handleChange={field.onChange}
                    />
                  </Box>
                ) : null}
              </>
            </Box>
          );
        })}
      </Box>
      <Box className="project-details-form-action">
        <ActionButton
          buttonText={"Cancel"}
          onClick={() => navigate("/projects")}
          isAlternateButton={true}
        />
        <ActionButton
          className="project-details-save-button"
          disabled={saveButtonDisabled}
          buttonText="Save"
          onClick={
            projects.projectDetailsUpdateLoading
              ? null
              : () => {
                  onSave();
                }
          }
        />
      </Box>
      <FilterModal
        open={addWsrModalVisibility}
        title={
          detailPayload?.wsrLink?.length > 0 ? "Edit Wsr Link" : "Add Wsr Link"
        }
        handleClose={onCloseFilterModal}
        handleSave={onUploadWsr}
        saveButtonText="Save"
        content={wsrContent()}
      />
    </Box>
  );
}

export default connect(({ projects, users }) => ({ projects, users }), {
  getProjectDetailsRequest,
  updateProjectDetailsRequest,
  updateWsrLinkRequest,
  resetAlertRequest,
})(ProjectDetails);
