import { Types } from "../actions/projects";

const INITIAL_STATE = {
  items: [],
  wsrStatus: [],
  projects: [],
  totalProjectCount: 0,
  loading: true,
  error: "",
  projectDetails: {},
  projectDetailsLoading: true,
  projectDetailsError: "",
  wsrUploading: false,
  wsrUploadingError: "",
  wsrUploadSuccess: false,
  projectDetailsUpdateSuccess: false,
  emailSendError: "",
  bulkEmailSuccessMessage:"",
  bulkEmailSendError:"",
  projectFilter: {
    role: null,
    weekEndDate: null,
    projectOwner: null,
    wsrStatusId: null,
    wsrLinkAdded: null,
    WsrReviewerFilter: false,
    pagingLimit: 10,
    pagingOffset: 0,
    isAssignedToMe: null,
  }
};

export const ProjectsReducer = (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case Types.SEARCH_PROJECTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Types.GET_PROJECTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Types.GET_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: actions.payload.items,
        totalProjectCount: actions.payload.totalProjectCount,
      };
    case Types.GET_WSR_SUCCESS:
      return {
        ...state,
        loading: false,
        wsrStatus: actions.payload.items,
      };
    case Types.PROJECTS_ERROR:
    case Types.WSR_ERROR:
      return {
        ...state,
        loading: false,
        error: actions.payload.error,
      };
    case Types.GET_PROJECT_DETAILS_REQUEST:
      return {
        ...state,
        projectDetailsLoading: true,
        projectDetailsError: "",
      };
    case Types.GET_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        projectDetailsLoading: false,
        projectDetails: actions.payload.items,
        projectDetailsError: "",
      };
    case Types.PROJECT_DETAILS_ERROR:
      return {
        ...state,
        projectDetailsLoading: false,
        projectDetailsError: actions.payload.error,
      };
    case Types.UPDATE_PROJECT_DETAILS_REQUEST:
      return {
        ...state,
        projectDetailsUpdateLoading: true,
        projectDetailsUpdateSuccess: false,
        projectDetailsUpdateError: "",
      };
    case Types.UPDATE_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        projectDetailsUpdateLoading: false,
        projectDetailsUpdateSuccess: true,
        projectDetailsUpdateError: "",
      };
    case Types.UPDATE_PROJECT_DETAILS_ERROR:
      return {
        ...state,
        projectDetailsUpdateLoading: false,
        projectDetailsUpdateSuccess: false,
        projectDetailsUpdateError: actions.payload.error,
      };
    case Types.UPDATE_WSR_LINK_REQUEST:
      return {
        ...state,
        wsrUploading: true,
        wsrUploadSuccess: false,
      };
    case Types.UPDATE_WSR_LINK_SUCCESS:
      return {
        ...state,
        wsrUploading: false,
        wsrUploadSuccess: true,
      };
    case Types.UPDATE_WSR_LINK_ERROR:
      return {
        ...state,
        wsrUploading: false,
        wsrUploadSuccess: false,
        wsrUploadingError: actions.payload.error,
      };
    case Types.RESET_ALERT_REQUEST:
      return {
        ...state,
        wsrUploading: false,
        wsrUploadSuccess: false,
        wsrUploadingError: "",
        projectDetailsUpdateLoading: false,
        projectDetailsUpdateSuccess: false,
        projectDetailsUpdateError: "",
        projectDetailsLoading: false,
        projectDetailsError: "",
        emailSendError:"",
        bulkEmailSuccessMessage:"",
        bulkEmailSendError:""
      };
    case Types.BULK_SEND_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
        bulkEmailSendError: '',
        bulkEmailSuccessMessage:''
      };
    case Types.BULK_SEND_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        bulkEmailSuccessMessage: actions.payload.items,
        bulkEmailSendError: '',
      };
    case Types.BULK_SEND_EMAIL_ERROR:
      return {
        ...state,
        loading: false,
        bulkEmailSuccessMessage:'',
        bulkEmailSendError: actions.payload.error,
      };
    case Types.UPDATE_PROJECT_FILTER: 
      return {
          ...state,
          projectFilter: {
            ...state.projectFilter,
            ...actions.payload
          }
        };
    default:
      return state;
  }
};
