import * as actions from "../actions/users";
import { takeEvery, call, fork, put } from "redux-saga/effects";
import { getCurrentUser, getRoles, getUsers } from "../apis/users";

function* getUsersList(data) {
  try {
    let result = yield call(getUsers, {
      ...data.payload,
    });
    yield put(
      actions.getUsersSuccess({
        items: result.data || [],
      })
    );
  } catch (e) {
    yield put(
      actions.usersError({
        error: (e.response && e.response?.data) || "An error occured while trying to get the users.",
      })
    );
  }
}

function* getCurrentUserData(data) {
  try {
    let result = yield call(getCurrentUser);
    yield put(
      actions.getCurrentUserSuccess({
        user: result.data || {},
      })
    );
  } catch (result) {
    yield put(
      actions.currentUserError(result.response)
    );
  }
}

function* getRolesList() {
  try {
    let result = yield call(getRoles);
    yield put(
      actions.getRolesSuccess({
        items: result.data || [],
      })
    );
  } catch (e) {
    yield put(
      actions.rolesError({
        error: (e.response && e.response?.data) || "An error occured while trying to get roles.",
      })
    );
  }
}

function* watchGetUsersRequest() {
  yield takeEvery(actions.Types.GET_USERS_REQUEST, getUsersList);
}

function* watchGetCurrentUserRequest() {
  yield takeEvery(actions.Types.GET_CURRENT_USER_REQUEST, getCurrentUserData);
}

function* watchGetRolesRequest() {
  yield takeEvery(actions.Types.GET_ROLES_REQUEST, getRolesList);
}

const userSagas = [
  fork(watchGetUsersRequest),
  fork(watchGetRolesRequest),
  fork(watchGetCurrentUserRequest),
];
export default userSagas;
