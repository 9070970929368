import axios from "axios";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, loginRequest } from "./authConfig";
import config from "./config";
const msalInstance = new PublicClientApplication(msalConfig);

const api = axios.create({
  baseURL: config[process.env.REACT_APP_ENV].apiUrl,
});

// Add a request interceptor
api.interceptors.request.use(
  async (config) => {
    const account = msalInstance.getAllAccounts()[0];
    const msalResponse = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });
    config.headers.Authorization = `Bearer ${ msalResponse.accessToken }`;
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

export default api;
