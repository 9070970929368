import { takeEvery, call, fork, put, throttle } from "redux-saga/effects";
import * as actions from "../actions/projects";
import {
  getProjectList,
  getProjectDetails,
  updateProjectDetailsApi,
  updateWsrLink,
  getWsrStatus,
  bulkSendEmail
} from "../apis/projects";

function* getProjects(data) {
  try {
    let result = yield call(getProjectList, {
      ...data.payload,
    });
    yield put(
      actions.getProjectsSuccess({
        items: result.data.projectDetails || [],
        totalProjectCount: result.data.totalProjectsCount || 0,
      })
    );
  } catch (e) {
    yield put(
      actions.projectsError({
        error: (e.response && e.response?.data) || "An error occured while trying to get the projects.",
      })
    );
  }
}

function* getWsrs() {
  try {
    let result = yield call(getWsrStatus);
    yield put(
      actions.getWSRSuccess({
        items: result.data || [],
      })
    );
  } catch (e) {
    yield put(
      actions.wsrError({
        error: (e.response && e.response?.data) || "An error occured while trying to get wsr status list.",
      })
    );
  }
}

function* fetchProjectDetails(data) {
  try {
    let result = yield call(getProjectDetails, {
      ...data.payload,
    });
    yield put(
      actions.getProjectDetailsSuccess({
        items: result.data || {},
      })
    );
  } catch (e) {
    yield put(
      actions.projectDetailsError({
        error: (e.response && e.response?.data) || "An error occured while trying to get the project details",
      })
    );
  }
}

function* updateProjectDetails(data) {
  const modifiedPayload = { ...data.payload };
  delete modifiedPayload.navigateOnSuccess;
  try {
    let result = yield call(updateProjectDetailsApi, {
      ...modifiedPayload,
    });
    yield put(
      actions.updateProjectDetailsSuccess({
        items: result.data || {},
      })
    );
    data.payload.navigateOnSuccess();
  } catch (e) {
    yield put(
      actions.updateprojectDetailsError({
        error: (e.response && e.response?.data) || "An error occured while trying to update the project details",
      })
    );
  }
}

function* updateWsr(data) {
  try {
    let result = yield call(updateWsrLink, {
      ...data.payload,
    });
    yield put(
      actions.updateWsrLinkSuccess({
        items: result.data || [],
      })
    );
  } catch (e) {
    yield put(
      actions.updateWsrLinkError({
        error: (e.response && e.response?.data) || "An error occured while trying to update the WSR link",
      })
    );
  }
}

function* bulkSendEmailToCustomer(data) {
  try {
    let result = yield call(bulkSendEmail, {
      ...data.payload,
    });
    if (result.status === 200) { 
      yield put(
        actions.bulkEmailSendSuccess({
          items: result.data || [],
        })
      );
      let project = yield call(getProjectList, {
        ...data.projectPayload,
      });

      yield put(
        actions.getProjectsSuccess({
          items: project.data.projectDetails || [],
          totalProjectCount: project.data.totalProjectsCount || 0,
        })
      );
    } else {
      yield put(
        actions.bulkEmailSendError({
          error: "An error occured while trying to send the Email to Customer.",
        })
      );
    }
  } catch (e) {
    yield put(
      actions.emailSendError({
        error: (e.response && e.response?.data) || "An error occured while trying to send the Email to Customer.",
      })
    );
  }
}

function updateProjectFilterCallback(payload) { 
  if (payload.cb) {
    payload.cb(payload.payload);
  }
}

function* watchGetProjectsRequest() {
  yield takeEvery(actions.Types.GET_PROJECTS_REQUEST, getProjects);
  yield takeEvery(actions.Types.UPDATE_PROJECT_FILTER, updateProjectFilterCallback);
  yield takeEvery(actions.Types.BULK_SEND_EMAIL_REQUEST, bulkSendEmailToCustomer);
  yield takeEvery(
    actions.Types.GET_PROJECT_DETAILS_REQUEST,
    fetchProjectDetails
  );
  yield takeEvery(actions.Types.UPDATE_WSR_LINK_REQUEST, updateWsr);
  yield takeEvery(
    actions.Types.UPDATE_PROJECT_DETAILS_REQUEST,
    updateProjectDetails
  );
  yield throttle(2000, actions.Types.SEARCH_PROJECTS_REQUEST, getProjects);
}

function* watchGetWSRRequest() {
  yield takeEvery(actions.Types.GET_WSR_REQUEST, getWsrs);
}

const projectSagas = [fork(watchGetProjectsRequest), fork(watchGetWSRRequest)];
export default projectSagas;