export const Types = {
  GET_WSR_REQUEST: "get_wsr_request",
  GET_PROJECTS_REQUEST: "get_projects_request",
  GET_WSR_SUCCESS: "get_wsr_success",
  GET_PROJECTS_SUCCESS: "get_projects_success",
  WSR_ERROR: "wsr_error",
  PROJECTS_ERROR: "project_error",
  GET_PROJECT_DETAILS_REQUEST: "GET_PROJECT_DETAILS_REQUEST",
  GET_PROJECT_DETAILS_SUCCESS: "GET_PROJECT_DETAILS_SUCCESS",
  PROJECT_DETAILS_ERROR: "PROJECT_DETAILS_ERROR",
  UPDATE_PROJECT_DETAILS_REQUEST: "UPDATE_PROJECT_DETAILS_REQUEST",
  UPDATE_PROJECT_DETAILS_SUCCESS: "UPDATE_PROJECT_DETAILS_SUCCESS",
  UPDATE_PROJECT_DETAILS_ERROR: "UPDATE_PROJECT_DETAILS_ERROR",
  UPDATE_WSR_LINK_REQUEST: "UPDATE_WSR_LINK_REQUEST",
  UPDATE_WSR_LINK_SUCCESS: "UPDATE_WSR_LINK_SUCCESS",
  UPDATE_WSR_LINK_ERROR: "UPDATE_WSR_LINK_ERROR",
  BULK_SEND_EMAIL_REQUEST: "BULK_SEND_EMAIL_REQUEST",
  BULK_SEND_EMAIL_SUCCESS: "BULK_SEND_EMAIL_SUCCESS",
  BULK_SEND_EMAIL_ERROR: "BULK_SEND_EMAIL_ERROR",
  RESET_ALERT_REQUEST: "RESET_ALERT_REQUEST",
  UPDATE_PROJECT_FILTER: "UPDATE_PROJECT_FILTER",
  SEARCH_PROJECTS_REQUEST: "SEARCH_PROJECTS_REQUEST",
};

export const getWSRRequest = (payload) => ({
  type: Types.GET_WSR_REQUEST,
  payload: payload,
});

export const getProjectsRequest = (payload) => ({
  type: Types.GET_PROJECTS_REQUEST,
  payload: payload,
});

export const getSearchProjectsRequest = (payload) => ({
  type: Types.SEARCH_PROJECTS_REQUEST,
  payload: payload,
});

export const getWSRSuccess = ({ items }) => ({
  type: Types.GET_WSR_SUCCESS,
  payload: { items },
});

export const getProjectsSuccess = ({ items, totalProjectCount }) => ({
  type: Types.GET_PROJECTS_SUCCESS,
  payload: { items: items, totalProjectCount: totalProjectCount },
});

export const wsrError = ({ error }) => ({
  type: Types.WSR_ERROR,
  payload: { error },
});

export const projectsError = ({ error }) => ({
  type: Types.PROJECTS_ERROR,
  payload: { error },
});

export const getProjectDetailsRequest = (payload) => ({
  type: Types.GET_PROJECT_DETAILS_REQUEST,
  payload: payload,
});

export const getProjectDetailsSuccess = ({ items }) => ({
  type: Types.GET_PROJECT_DETAILS_SUCCESS,
  payload: { items },
});

export const projectDetailsError = ({ error }) => ({
  type: Types.PROJECT_DETAILS_ERROR,
  payload: { error },
});

export const updateProjectDetailsRequest = (payload) => ({
  type: Types.UPDATE_PROJECT_DETAILS_REQUEST,
  payload: payload,
});

export const updateProjectDetailsSuccess = ({ items }) => ({
  type: Types.UPDATE_PROJECT_DETAILS_SUCCESS,
  payload: { items },
});

export const updateprojectDetailsError = ({ error }) => ({
  type: Types.UPDATE_PROJECT_DETAILS_ERROR,
  payload: { error },
});

export const updateWsrLinkRequest = (payload) =>({
  type: Types.UPDATE_WSR_LINK_REQUEST,
  payload: payload,
});

export const updateWsrLinkSuccess =({ items }) =>({
  type: Types.UPDATE_WSR_LINK_SUCCESS,
  payload: { items },
});

export const updateWsrLinkError = ({ error }) => ({
  type: Types.UPDATE_WSR_LINK_ERROR,
  payload: { error },
});

export const emailSendSuccess = ({ items }) =>({
  type: Types.SEND_EMAIL_SUCCESS,
  payload: { items },
});

export const emailSendError = ({ error }) => ({
  type: Types.SEND_EMAIL_ERROR,
  payload: { error },
});

export const bulkEmailSendRequest =( payload, projectPayload) =>({
  type: Types.BULK_SEND_EMAIL_REQUEST,
  payload: payload,
  projectPayload: projectPayload
});

export const bulkEmailSendSuccess = ({ items }) =>({
  type: Types.BULK_SEND_EMAIL_SUCCESS,
  payload: { items },
});

export const bulkEmailSendError = ({ error }) => ({
  type: Types.BULK_SEND_EMAIL_ERROR,
  payload: { error },
});
export const resetAlertRequest = () => ({
  type: Types.RESET_ALERT_REQUEST
});

export const updateProjectFilter = (payload, cb = null) => ({
  type: Types.UPDATE_PROJECT_FILTER,
  payload: payload,
  cb: cb
});