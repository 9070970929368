import React from "react";
import { useMsal } from "@azure/msal-react";
import Box from "@mui/material/Box";
import { loginRequest } from "./authConfig";
import LoginButtton from "./shared/login-button";
import microsoftLogo from "./images/microsoft-logo.png";
import optimusLogo from "./images/optimusinfo-logo.png";
import backgroundImage from "./images/background.jfif";

const Login = ({ unAuthorize = false }) => {
  /**
   * useMsal is a hook that returns the PublicClientApplication instance.
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const handleLoginRedirect = () => {
    instance
      .loginRedirect(loginRequest)
      .catch((error) => console.log("error", error));
  };

  const handleLogoutRedirect = () => {
    instance.logoutRedirect().catch((error) => console.log(error));
  };

  return (
    <Box>
      <Box
        sx={{ backgroundImage: `url(${backgroundImage})` }}
        className="login-container"
      />
      <Box
        sx={{ padding: { md: "50px", xs: "20px" } }}
        className="login-container-box"
      >
        <Box
          sx={{
            flexDirection: { md: "row", xs: "column" },
            padding: { md: "40px", xs: "20px" },
          }}
          className="login-container-inner-box"
        >
          <Box
            sx={{ height: { md: "150px", xs: "80px" } }}
            className="login-company-logo"
          >
            <img
              src={optimusLogo}
              width="auto"
              height="100%"
              alt="optimus-logo"
            />
          </Box>
          <Box
            sx={{
              borderLeft: { md: "3px solid #DD4B39", xs: "0px solid #DD4B39" },
              borderBottom: {
                md: "0px solid #DD4B39",
                xs: "3px solid #DD4B39",
              },
            }}
            className="login-divide-line"
          />
          <Box
            sx={{ marginLeft: { md: "60px", xs: "0px" } }}
            className="login-project-title-container"
          >
            <Box
              sx={{
                fontSize: { md: "34px", fontSize: "18px" },
                marginBottom: { md: "16px", xs: "8px" },
              }}
              className="login-project-title-text"
            >
              {" "}
              WSR Circulation
            </Box>
            {unAuthorize ? (
              <>
                <Box mb={2} sx={{color:'#f00'}}>
                  {activeAccount?.name.toUpperCase() + "," || ""} you are not
                  Authorized to this App.{" "}
                </Box>
                <LoginButtton
                variant="outlined"
                onClick={handleLogoutRedirect}
                sx={{ fontSize: { md: "20px", fontSize: "16px" } }}
              >
                Logout
                </LoginButtton>
              </>
            ) : (
              <LoginButtton
                variant="outlined"
                onClick={handleLoginRedirect}
                sx={{ fontSize: { md: "24px", fontSize: "16px" } }}
              >
                <Box
                  sx={{ height: { md: "40px", xs: "24px" } }}
                  className="login-company-logo cursor-pointer"
                >
                  <img
                    src={microsoftLogo}
                    height="100%"
                    width="auto"
                    alt="microsoft-logo"
                  />
                </Box>{" "}
                Sign in with Microsoft
              </LoginButtton>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Login;
