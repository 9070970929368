const config = {
    "local":{
        apiUrl : "https://frontdoor-wsr-circulation-02.azurefd.net/api",
        apiSCope : "api://2e6262ca-73f9-47b3-bcc1-4beda2c49f3d/user_impersonation",
        wsrValidDomain:"https://optimusinfo.sharepoint.com",
        msalAuthConfig: {
            clientId: 'e93731f2-4679-4aed-a206-1400a4c55f0a', // This is the ONLY mandatory field that you need to supply.
            authority: 'https://login.microsoftonline.com/b5db11ac-8f37-4109-a146-5d7a302f5881', // Defaults to "https://login.microsoftonline.com/common"
            redirectUri: 'http://localhost:3000/redirect', // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
            postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
            navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
        }
    },
    "dev": {
        apiUrl : "https://frontdoor-wsr-circulation-02.azurefd.net/api",
        apiSCope : "api://2e6262ca-73f9-47b3-bcc1-4beda2c49f3d/user_impersonation",
        wsrValidDomain:"https://optimusinfo.sharepoint.com",
        msalAuthConfig: {
            clientId: 'e93731f2-4679-4aed-a206-1400a4c55f0a', // This is the ONLY mandatory field that you need to supply.
            authority: 'https://login.microsoftonline.com/b5db11ac-8f37-4109-a146-5d7a302f5881', // Defaults to "https://login.microsoftonline.com/common"
            redirectUri: 'https://frontdoor-wsr-circulation-01.azurefd.net/.auth/login/aad/callback', // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
            postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
            navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
        }
    },
    "dev2": {
        apiUrl : "https://frontdoor-wsr-circulation-02.azurefd.net/api",
        apiSCope : "api://2e6262ca-73f9-47b3-bcc1-4beda2c49f3d/user_impersonation",
        wsrValidDomain:"https://optimusinfo.sharepoint.com",
        msalAuthConfig: {
            clientId: 'e93731f2-4679-4aed-a206-1400a4c55f0a', // This is the ONLY mandatory field that you need to supply.
            authority: 'https://login.microsoftonline.com/b5db11ac-8f37-4109-a146-5d7a302f5881', // Defaults to "https://login.microsoftonline.com/common"
            redirectUri: 'https://frontdoor-wsr-circulation-01.azurefd.net/.auth/login/aad/callback', // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
            postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
            navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
        }
    },
    "testing":{
        apiUrl : "https://frontdoor-wsr-circulation-02.azurefd.net/api",
        apiSCope : "api://2e6262ca-73f9-47b3-bcc1-4beda2c49f3d/user_impersonation",
        wsrValidDomain:"https://optimusinfo.sharepoint.com",
        msalAuthConfig: {
            clientId: 'e93731f2-4679-4aed-a206-1400a4c55f0a', // This is the ONLY mandatory field that you need to supply.
            authority: 'https://login.microsoftonline.com/b5db11ac-8f37-4109-a146-5d7a302f5881', // Defaults to "https://login.microsoftonline.com/common"
            // redirectUri: 'http://localhost:3000/redirect', // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
            redirectUri: 'https://webapp-wsr-circulation-dev-001.azurewebsites.net/redirect', // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
            postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
            navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
        }
    },
    "staging":{
        apiUrl : "https://apim-wsr-circulation-stage01.azure-api.net/api",
        apiSCope : "api://2d1b9ae1-9381-4719-9f10-7863bef21a70/user_impersonation",
        wsrValidDomain:"https://optimusinfo.sharepoint.com",
        msalAuthConfig: {
            clientId: '7d84b6b1-39c9-4149-9fd1-86dee48fa131', // This is the ONLY mandatory field that you need to supply.
            authority: 'https://login.microsoftonline.com/b5db11ac-8f37-4109-a146-5d7a302f5881', // Defaults to "https://login.microsoftonline.com/common"
            // redirectUri: 'http://localhost:3000/redirect', // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
            redirectUri: 'https://webapp-wsr-circulation-stage.azurewebsites.net/redirect', // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
            postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
            navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
        }
    },
    "prod":{
        apiUrl : "https://apim-wsr-circulation-prod01.azure-api.net/api",
        apiSCope : "api://a68abc13-6d1a-4898-ba57-3a6f9f6571d4/user_impersonation",
        wsrValidDomain:"https://optimusinfo.sharepoint.com",
        msalAuthConfig: {
            clientId: '0161ab89-404d-46e0-9390-75bd6026b678', // This is the ONLY mandatory field that you need to supply.
            authority: 'https://login.microsoftonline.com/b5db11ac-8f37-4109-a146-5d7a302f5881', // Defaults to "https://login.microsoftonline.com/common"
            redirectUri: 'https://wsr.optimusinfo.com/redirect', // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
            postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
            navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
        }
    }
}

export default config;
