import { styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";

const LoginButton = styled(MuiButton)((props) => ({
    color: '#000000',
    border: '1px solid #000000',
    borderRadius: 0,
    fontSize: "24px",
    textTransform: 'none',
    ":hover": {
        backgroundColor:"#FFFFFF",
        border: '1px solid #000000'
    }
}));

export default LoginButton;