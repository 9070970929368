import api from "../api";

export const getUsers = () => {
  return api.get(`/users`);
};

export const getRoles = () => {
  return api.get("/roles");
};

export const getCurrentUser = () => {
  return api.get("/users/current");
};
