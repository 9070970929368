export const Types = {
  GET_ACTION_ITEMS_REQUEST: "get_action_items_request",
  ADD_ACTION_ITEMS_REQUEST: "add_action_items_request",
  UPDATE_ACTION_ITEMS_REQUEST: "update_action_items_request",
  DELETE_ACTION_ITEMS_REQUEST: "delete_action_items_request",
  GET_ALL_PROJECTS_REQUEST: "get_all_projects_request",
  GET_ACTION_ITEM_STATUS_REQUEST: "get_action_items_status_request",
  GET_ACTION_ITEMS_SUCCESS: "get_action_items_success",
  ADD_ACTION_ITEMS_SUCCESS: "add_action_items_success",
  UPDATE_ACTION_ITEMS_SUCCESS: "update_action_items_success",
  DELETE_ACTION_ITEMS_SUCCESS: "delete_action_items_success",
  GET_ACTION_ITEM_STATUS_SUCCESS: "get_action_items_status_success",
  GET_ALL_PROJECTS_SUCCESS: "get_all_projects_success",
  ACTION_ITEMS_ERROR: "action_items_error",
  ADD_ACTION_ITEMS_ERROR: "add_action_items_error",
  UPDATE_ACTION_ITEMS_ERROR: "update_action_items_error",
  DELETE_ACTION_ITEMS_ERROR: "delete_action_items_error",
  ACTION_ITEM_STATUSES_ERROR: "action_item_status_error",
  GET_ALL_PROJECTS_ERROR: "get_all_projects_error",
  RESET_ALERT_REQUEST: "RESET_ALERT_REQUEST",
};

export const getActionItemsRequest = (payload) => ({
  type: Types.GET_ACTION_ITEMS_REQUEST,
  payload: payload,
});

export const addActionItemsRequest = (
  payload,
  getItemsPayload,
  isProject = false
) => ({
  type: Types.ADD_ACTION_ITEMS_REQUEST,
  payload: payload,
  getItemsPayload: getItemsPayload,
  isProject: isProject,
});

export const updateActionItemsRequest = (payload, getItemsPayload) => ({
  type: Types.UPDATE_ACTION_ITEMS_REQUEST,
  payload: payload,
  getItemsPayload: getItemsPayload,
});

export const deleteActionItemRequest = (id, getItemsPayload) => ({
  type: Types.DELETE_ACTION_ITEMS_REQUEST,
  id: id,
  getItemsPayload: getItemsPayload,
});

export const getAllProjectsRequest = (selectedDate) => ({
  type: Types.GET_ALL_PROJECTS_REQUEST,
  selectedDate: selectedDate,
});

export const getActionItemStatusesRequest = () => ({
  type: Types.GET_ACTION_ITEM_STATUS_REQUEST,
});

export const getActionItemsSuccess = ({ items, totalItemCount }) => ({
  type: Types.GET_ACTION_ITEMS_SUCCESS,
  payload: { items: items, totalItemCount: totalItemCount },
});

export const addActionItemsSuccess = () => ({
  type: Types.ADD_ACTION_ITEMS_SUCCESS,
});

export const updateActionItemsSuccess = () => ({
  type: Types.UPDATE_ACTION_ITEMS_SUCCESS,
});

export const deleteActionItemSuccess = () => ({
  type: Types.DELETE_ACTION_ITEMS_SUCCESS,
});

export const getAllProjectsSuccess = ({ projects }) => ({
  type: Types.GET_ALL_PROJECTS_SUCCESS,
  payload: { projects: projects },
});

export const getActionItemStatusesSuccess = ({ items }) => ({
  type: Types.GET_ACTION_ITEM_STATUS_SUCCESS,
  payload: { actionItemStatuses: items },
});

export const actionItemsError = ({ error }) => ({
  type: Types.ACTION_ITEMS_ERROR,
  payload: { error },
});

export const addActionItemsError = ({ error }) => ({
  type: Types.ACTION_ITEMS_ERROR,
  payload: { error },
});

export const updateActionItemsError = ({ error }) => ({
  type: Types.UPDATE_ACTION_ITEMS_ERROR,
  payload: { error },
});

export const getAllProjectsError = ({ error }) => ({
  type: Types.GET_ALL_PROJECTS_ERROR,
  payload: { error },
});

export const actionItemStatusesError = ({ error }) => ({
  type: Types.ACTION_ITEM_STATUSES_ERROR,
  payload: { error },
});

export const deleteActionItemsError = ({ error }) => ({
  type: Types.DELETE_ACTION_ITEMS_ERROR,
  payload: { error },
});

export const resetActionAlertRequest = () => ({
  type: Types.RESET_ALERT_REQUEST,
});
