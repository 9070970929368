import * as React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";

export default function CustomCheckbox({
  checked,
  handleChange,
  label,
  disabled = false,
}) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          disabled={disabled}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
          sx={{
            color: "#dd4b39",
            "&.Mui-checked": {
              color: "#dd4b39",
            },
            "&.Mui-disabled": {
              pointerEvents: "auto",
              "&:hover": {
                backgroundColor: "transparent",
              },
              cursor: "not-allowed",
            },
          }}
        />
      }
      label={label}
    />
  );
}
