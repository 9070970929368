import * as React from "react";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import _, { startCase } from "lodash";
import "./header.css";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { useLocation } from "react-router";
import { useMsal } from "@azure/msal-react";
import { connect } from "react-redux";
import { getCurrentUserRequest } from "../../actions/users";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, drawerwidth }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#dd4b39",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerwidth}px)`,
    marginLeft: `${drawerwidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Header = ({ open, drawerWidth, handleDrawerOpen, users }) => {
  const { instance } = useMsal();
  const location = useLocation();
  const routes = ["projects", "project-details", "action-items"];

  const handleLogoutRedirect = () => {
    sessionStorage.clear();
    instance.logoutRedirect().catch((error) => console.log(error));
  };

  const createBreadcrumsJsx = () => {
    let currentPath = [];
    const path = location.pathname.split("/");
    if (location.pathname === "/") {
      currentPath.push(_.startCase("Dashboard"));
    } else {
      path.forEach((p) => {
        if (routes.includes(p)) {
          currentPath.push(_.startCase(p));
        }
      });
    }
    return (
      <>
        <Typography
          pr={1}
          className="cursor-pointer"
          variant="h6"
          noWrap
          component="div"
          onClick={() => window.open("https://www.optimusinfo.com/", "_blank")}
        >
          Optimus Information
        </Typography>
        {currentPath.map((item, index) => (
          <Typography
            key={index}
            className="page-name"
            component="div"
            pr={1}
            pl={1}
            pt={0.3}
          >
            {item}
          </Typography>
        ))}
      </>
    );
  };

  return (
    <AppBar position="fixed" open={open} drawerwidth={drawerWidth}>
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Box display="flex" alignItems={"center"} className="brand-name">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          {createBreadcrumsJsx()}
        </Box>
        <Box className={"app-name"}>WSR Circulation</Box>
        <Box className="user-Name">
          <Typography
            id="username"
            component={"span"}
            title={users.currentUser.email}
          >
            {startCase(users.currentUser.name)}
          </Typography>
          <Typography component="span" title="Logout">
            <LogoutIcon
              className="cursor-style"
              id="logout-icon"
              onClick={() => handleLogoutRedirect()}
            />
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default connect(({ users }) => ({ users }), {
  getCurrentUserRequest,
})(Header);
