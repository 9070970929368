import { Types } from "../actions/action-items";

const INITIAL_STATE = {
  items: [],
  actionItemStatuses: [],
  projects: [],
  totalItemCount: 0,
  loading: true,
  formLoader: false,
  error: "",
  closeAddModal: false,
  closeDeleteModal: false,
};

export const ActionItemsReducer = (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case Types.GET_ACTION_ITEMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Types.GET_ACTION_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: actions.payload.items,
        totalItemCount: actions.payload.totalItemCount,
      };
    case Types.ADD_ACTION_ITEMS_REQUEST:
    case Types.UPDATE_ACTION_ITEMS_REQUEST:
      return {
        ...state,
        formLoader: true,
        closeAddModal: false,
      };
    case Types.ADD_ACTION_ITEMS_SUCCESS:
    case Types.UPDATE_ACTION_ITEMS_SUCCESS:
      return {
        ...state,
        formLoader: false,
        closeAddModal: true,
      };
    case Types.GET_ACTION_ITEM_STATUS_SUCCESS:
      return {
        ...state,
        actionItemStatuses: actions.payload.actionItemStatuses,
      };
    case Types.GET_ALL_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: actions.payload.projects,
      };
    case Types.DELETE_ACTION_ITEMS_REQUEST:
      return {
        ...state,
        closeDeleteModal: false,
      };
    case Types.DELETE_ACTION_ITEMS_SUCCESS:
      return {
        ...state,
        closeDeleteModal: true,
      };
    case Types.ACTION_ITEMS_ERROR:
    case Types.ADD_ACTION_ITEMS_ERROR:
    case Types.DELETE_ACTION_ITEMS_ERROR:
    case Types.UPDATE_ACTION_ITEMS_ERROR:
    case Types.GET_ALL_PROJECTS_ERROR:
    case Types.ACTION_ITEM_STATUSES_ERROR:
      return {
        ...state,
        loading: false,
        error: actions.payload.error,
        closeDeleteModal: true,
        closeAddModal: true,
      };
    case Types.RESET_ALERT_REQUEST:
      return {
        ...state,
        error: "",
        loading: false,
        closeDeleteModal: true,
        closeAddModal: true,
      };
    default:
      return state;
  }
};
