import { takeEvery, call, fork, put } from "redux-saga/effects";
import * as actions from "../actions/action-items";
import * as projectActions from "../actions/projects";
import {
  addActionItems,
  getActionItems,
  getActionItemStatuses,
  removeActionItem,
  updateActionItems,
} from "../apis/action-items";
import { getAllProjects } from "../apis/projects";

function* getActionItemList(data) {
  try {
    let result = yield call(getActionItems, {
      ...data.payload,
    });
    yield put(
      actions.getActionItemsSuccess({
        items: result.data.data || [],
        totalItemCount: result.data.totalCount || 0,
      })
    );
  } catch (e) {
    yield put(
      actions.actionItemsError({
        error: (e.response && e.response?.data) || "An error occured while trying to get the action items.",
      })
    );
  }
}

function* addActionItem(data) {
  try {
    yield call(addActionItems, {
      ...data.payload,
    });
    yield put(actions.addActionItemsSuccess());
    if (data.isProject) {
      yield put(projectActions.getProjectsRequest(data.getItemsPayload));
    } else {
      yield put(actions.getActionItemsRequest(data.getItemsPayload));
    }
  } catch (e) {
    
    yield put(
      actions.addActionItemsError({
        error: (e.response && e.response?.data) || "An error occured while trying to add an action item.",
      })
    );
  }
}

function* updateActionItem(data) {
  try {
    yield call(updateActionItems, {
      ...data.payload,
    });
    yield put(actions.updateActionItemsSuccess());
    yield put(actions.getActionItemsRequest(data.getItemsPayload));
  } catch (e) {
    yield put(
      actions.updateActionItemsError({
        error: (e.response && e.response?.data) || "An error occured while trying to update an action item.",
      })
    );
  }
}

function* deleteActionItem(data) {
  try {
    yield call(removeActionItem, data.id);
    yield put(actions.deleteActionItemSuccess());
    yield put(actions.getActionItemsRequest(data.getItemsPayload));
  } catch (e) {
    yield put(
      actions.deleteActionItemsError({
        error: (e.response && e.response?.data) || "An error occured while trying to delete an action item.",
      })
    );
  }
}

function* fetchActionItemStatuses() {
  try {
    let result = yield call(getActionItemStatuses);
    yield put(
      actions.getActionItemStatusesSuccess({
        items: result.data || [],
      })
    );
  } catch (e) {
    yield put(
      actions.actionItemStatusesError({
        error: (e.response && e.response?.data) || "An error occured while trying to get the action item statuses.",
      })
    );
  }
}

function* fetchAllProjects(data) {
  try {
    let result = yield call(getAllProjects, data.selectedDate);
    yield put(
      actions.getAllProjectsSuccess({
        projects: result.data || [],
      })
    );
  } catch (e) {
    yield put(
      actions.getAllProjectsError({
        error: (e.response && e.response?.data) || "An error occured while trying to get all the projects.",
      })
    );
  }
}

function* watchGetActionItemsRequest() {
  yield takeEvery(actions.Types.GET_ACTION_ITEMS_REQUEST, getActionItemList);
}

function* watchAddActionItemsRequest() {
  yield takeEvery(actions.Types.ADD_ACTION_ITEMS_REQUEST, addActionItem);
}

function* watchAddActionItemStatusesRequest() {
  yield takeEvery(
    actions.Types.GET_ACTION_ITEM_STATUS_REQUEST,
    fetchActionItemStatuses
  );
}

function* watchUpdateActionItemRequest() {
  yield takeEvery(actions.Types.UPDATE_ACTION_ITEMS_REQUEST, updateActionItem);
}

function* watchGetAllProjectsRequest() {
  yield takeEvery(actions.Types.GET_ALL_PROJECTS_REQUEST, fetchAllProjects);
}

function* watchDeleteActionItemRequest() {
  yield takeEvery(actions.Types.DELETE_ACTION_ITEMS_REQUEST, deleteActionItem);
}

const actionItemsSagas = [
  fork(watchGetActionItemsRequest),
  fork(watchAddActionItemsRequest),
  fork(watchAddActionItemStatusesRequest),
  fork(watchGetAllProjectsRequest),
  fork(watchDeleteActionItemRequest),
  fork(watchUpdateActionItemRequest),
];
export default actionItemsSagas;
