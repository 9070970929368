import { Button, Tooltip } from "@mui/material";
import React from "react";

function ActionButton({
  isAlternateButton,
  buttonText,
  onClick,
  icon,
  variant,
  tooltip,
  color,
  className,
  disabled,
  endIcon
}) {
  const buttonJsx = (
    <Button
        onClick={onClick}
        endIcon={endIcon}
        variant={variant || "contained"}
        disabled={disabled}
        className={className || ""}
        sx={{
          backgroundColor: isAlternateButton ? "#fff" : color || "#dd4b39",
          border: !disabled && isAlternateButton ? "1px solid #dd4b39" : "none",
          color: isAlternateButton ? "#dd4b39" : "#fff",
          ":hover": { backgroundColor: isAlternateButton ? "#fff" : "#dd4b39" },
        }}
      >
        {icon && <span className="material-icons">{icon}</span>}
        {buttonText}
    </Button>
  );
  if (tooltip) {
    return (
      <Tooltip title={tooltip.title || ''} placement={tooltip.placement} arrow>
        {buttonJsx}
      </Tooltip>
    );
  
  } else {
    return buttonJsx;
  
  }
}

export default ActionButton;
