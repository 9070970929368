import * as React from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import "./loader.css";

export default function Loader() {
  return (
    <Stack className="loader-stack" spacing={2}>
      <CircularProgress className="loader-color"/>
    </Stack>
  );
}
