import * as React from "react";
import Box from "@mui/material/Box";
import ActionButton from "../../shared/header/action-button";
import ComboBox from "../../shared/auto-complete/autocomplete";
import Loader from "../../shared/loader/loader";
import _, { startCase } from "lodash";
import CustomCheckbox from "../../shared/Checkbox";
import DateDropdown from "../../shared/week-end-date-select/week-end-date-select";
import projectStyles from "../projects/index.module.css";
import { DataGrid } from "@mui/x-data-grid";
import { connect } from "react-redux";
import {
  getActionItemsRequest,
  addActionItemsRequest,
  updateActionItemsRequest,
  getActionItemStatusesRequest,
  getAllProjectsRequest,
  deleteActionItemRequest,
  resetActionAlertRequest,
} from "../../actions/action-items";
import { useEffect } from "react";
import { useState } from "react";
import { Modal as FilterModal } from "../../shared/modal";
import { Modal as DeleteActionItemModal } from "../../shared/modal";
import {
  formatDate,
  getDateValue,
  getMonthEndDateOrUpcomingFriday,
} from "../../shared/utils";
import { Stack, Typography } from "@mui/material";
import AddActionItemModal from "./add-action-item";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import actionItemStyles from "./index.module.css";
import Alert from "../../shared/notification";
import { useSearchParams } from "react-router-dom";

function ActionItems({
  getActionItemsRequest,
  getActionItemStatusesRequest,
  addActionItemsRequest,
  updateActionItemsRequest,
  getAllProjectsRequest,
  users,
  actionItems,
  deleteActionItemRequest,
  resetActionAlertRequest,
}) {
  const initialFilterState = {
    role: null,
    weekEndDate: getDateValue(getMonthEndDateOrUpcomingFriday()),
    assigneeUserId: null,
    wsrCirculationDetailsId: null,
    actionItemStatusId: null,
    pagingLimit: 10,
    pagingOffset: 0,
    isAssignedToMe: false,
  };
  const [searchParams] = useSearchParams();
  const wsrId = searchParams.get("wsrId");
  const weekEndDateFromProjectPage = searchParams.get("weekEndDate");
  const projectIdFromProjectPage = searchParams.get("projectId");
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [isAssignedToMe, setIsAssignedToMe] = useState(false);
  const [assignedTo, setAssignedTo] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const [actionItemStatus, setActionItemStatus] = useState(null);
  const [payload, setPayload] = useState({ ...initialFilterState });
  const [selectedDate, setSelectedDate] = useState("");
  const [actionItemIdToBeDeleted, setActionItemIdToBeDeleted] = useState(null);
  const [actionItemToBeUpdated, setActionItemToBeUpdated] = useState({});
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const {
    container,
    filter_row,
    break_word,
    no_rows_text,
    filter_container,
    grid_loader,
  } = projectStyles;
  const { icon_style } = actionItemStyles;

  useEffect(() => {
    getActionItemStatusesRequest();
    return () => {
      resetActionAlertRequest();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (weekEndDateFromProjectPage) {
      setSelectedDate(weekEndDateFromProjectPage);
      setPayload({
        ...payload,
        weekEndDate: weekEndDateFromProjectPage,
      });
    } else {
      setDateFromSessionStorage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekEndDateFromProjectPage]);

  useEffect(() => {
      updateAlert(actionItems.error, "error");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionItems.error]);

  useEffect(() => {
    users.currentUser.id && selectedDate && fetchActionItems();
    selectedDate && getAllProjectsRequest(selectedDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users.currentUser.id, selectedDate]);

  useEffect(() => {
    actionItems.closeAddModal && handleAddActionItemClose();
    actionItems.closeAddModal && handleUpdateActionItemClose();
    actionItems.closeDeleteModal && handleDeleteActionItemClose();
  }, [actionItems.closeAddModal, actionItems.closeDeleteModal]);

  useEffect(() => {
    if (projectIdFromProjectPage) {
      setProjectName(
        actionItems.projects.find(
          (project) => project.id === parseInt(projectIdFromProjectPage)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectIdFromProjectPage]);

  const setDateFromSessionStorage = () => {
    if (sessionStorage.getItem("weekEndDateActionItem")) {
      setSelectedDate(sessionStorage.getItem("weekEndDateActionItem"));
      setPayload({
        ...payload,
        weekEndDate: sessionStorage.getItem("weekEndDateActionItem"),
      });
    } else {
      const dateValue = getMonthEndDateOrUpcomingFriday();
      setSelectedDate(getDateValue(dateValue));
      setPayload({
        ...payload,
        weekEndDate: getDateValue(dateValue),
      });
      sessionStorage.setItem("weekEndDateActionItem", getDateValue(dateValue));
    }
  };

  const getPayloadForFetchingActionItems = (
    pageNumber = 0,
    selectedWeekDate = selectedDate
  ) => {
    let modifiedPayload = _.omitBy(payload, _.isNull);
    setPayload({ ...payload, pagingOffset: pageNumber });
    modifiedPayload.pagingOffset = pageNumber;
    modifiedPayload.weekEndDate = selectedWeekDate;
    modifiedPayload.wsrCirculationDetailsId =
      modifiedPayload.wsrCirculationDetailsId || wsrId || null;
    return modifiedPayload;
  };

  const fetchActionItems = (
    pageNumber = 0,
    selectedWeekDate = selectedDate
  ) => {
    getActionItemsRequest(
      getPayloadForFetchingActionItems(pageNumber, selectedWeekDate)
    );
  };

  const handleDeleteActionItemClose = () => {
    setOpenDeleteModal(false);
    setActionItemIdToBeDeleted(null);
  };

  const handleDeleteActionItemOpen = () => {
    setOpenDeleteModal(true);
  };

  const handleAddActionItemClose = () => {
    setOpenAddModal(false);
  };

  const handleUpdateActionItemOpen = () => {
    setOpenUpdateModal(true);
  };

  const handleUpdateActionItemClose = () => {
    setOpenUpdateModal(false);
  };

  const deleteActionItem = () => {
    deleteActionItemRequest(
      actionItemIdToBeDeleted,
      getPayloadForFetchingActionItems()
    );
  };

  const handleAddActionItemOpen = () => {
    setOpenAddModal(true);
  };

  const submitAddForm = (payload) => {
    addActionItemsRequest(payload, getPayloadForFetchingActionItems());
  };

  const submitUpdateForm = (payload) => {
    updateActionItemsRequest(payload, getPayloadForFetchingActionItems());
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleFilterClose = () => {
    setOpen(false);
  };

  const applyFilter = () => {
    fetchActionItems();
    handleFilterClose();
  };

  const handleCheckboxChange = (event, name) => {
    setIsAssignedToMe(event.target.checked);
    setPayload({ ...payload, isAssignedToMe: event.target.checked });
  };

  const filterContent = () => {
    const assignedToList = [...users.users];
    const actiontItemStatusList = [...actionItems.actionItemStatuses];
    const projectNames = actionItems.projects || [];
    return (
      <Box className={filter_container}>
        <Box mb={2}>
          <ComboBox
            label={"Project Name"}
            data={projectNames || []}
            value={projectName}
            handleChange={(e, newValue) => {
              setProjectName(newValue);
              setPayload({
                ...payload,
                wsrCirculationDetailsId: newValue.wsrCirculationDetails[0].id,
              });
            }}
          />
        </Box>
        <Box mb={2}>
          <ComboBox
            label={"Assigned To"}
            data={assignedToList}
            value={assignedTo}
            handleChange={(e, newValue) => {
              setAssignedTo(newValue);
              setPayload({ ...payload, assigneeUserId: newValue.id });
            }}
          />
        </Box>
        <Box mb={2}>
          <ComboBox
            label={"Action Item Status"}
            data={actiontItemStatusList || []}
            value={actionItemStatus}
            handleChange={(e, newValue) => {
              setActionItemStatus(newValue);
              setPayload({
                ...payload,
                actionItemStatusId: newValue.actionItemStatusId,
              });
            }}
          />
        </Box>
        <Box>
          <CustomCheckbox
            checked={isAssignedToMe}
            handleChange={(event) =>
              handleCheckboxChange(event, "isAssignedToMe")
            }
            label="Assigned To Me"
          />
        </Box>
      </Box>
    );
  };

  const clearFilters = () => {
    setProjectName(null);
    setAssignedTo(null);
    setActionItemStatus(null);
    setIsAssignedToMe(false);
    setPayload({ ...initialFilterState });
  };

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    setSelectedDate(selectedDate);
    fetchActionItems(payload.pagingOffset, selectedDate);
    sessionStorage.setItem("weekEndDateActionItem", selectedDate);
  };

  const onEditiconClick = (row) => {
    setActionItemToBeUpdated(row);
    handleUpdateActionItemOpen();
  };

  const columns = [
    {
      field: "serialNumber",
      headerName: "S.No",
      sortable: false,
      flex: 0.25,
      align: "center",
      renderCell: (params) => (
        <div className={break_word}>
          {params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1 || ""}
        </div>
      ),
    },
    {
      field: "description",
      headerName: "Action Item",
      type: "string",
      flex: 1,
      renderCell: (params) => <div className={break_word}>{params.value}</div>,
    },
    {
      field: "actionItemStatusName",
      headerName: "Status",
      type: "string",
      flex: 0.5,
    },
    {
      field: "wsrCirculationDetails.projectName",
      headerName: "Project Name",
      type: "string",
      flex: 1,
      renderCell: (params) => <div className={break_word}>{params.value}</div>,
      valueGetter: (params) => params.row.wsrCirculationDetails.projectName,
    },
    {
      field: "wsrCirculationDetails.url",
      headerName: "WSR Link",
      type: "link",
      valueGetter: (params) => params.row.wsrCirculationDetails.url,
      renderCell: (params) =>
        params.value ? (
          <a
            href={params.value}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            Click Here
          </a>
        ) : (
          <center>-</center>
        ),
    },
    {
      field: "wsrCirculationDetails.projectOwnerName",
      headerName: "Project Owner",
      type: "string",
      flex: 0.6,
      valueGetter: (params) =>
        startCase(params.row.wsrCirculationDetails.projectOwnerName),
    },
    {
      field: "assigneeUserName",
      headerName: "Assigned To",
      type: "string",
      flex: 0.6,
      valueGetter: (params) => startCase(params.value),
    },
    {
      field: "createdUserName",
      headerName: "Assigned By",
      type: "string",
      flex: 0.6,
      renderCell: (params) => <Box className={break_word}>{params.value}</Box>,
    },
    {
      field: "lastModifiedDate",
      headerName: "Updated On",
      type: "string",
      flex: 0.5,
      valueGetter: (params) => formatDate(params.value),
    },
    {
      field: "Actions",
      headerName: "Actions",
      sortable: false,
      flex: 0.4,
      renderCell: (params) => (
        <Box>
          <EditIcon
            className={icon_style}
            onClick={() => onEditiconClick(params.row)}
          />
          {users.currentUser.id === params.row.createdBy && (
            <Typography
              component={"span"}
              onClick={() => {
                handleDeleteActionItemOpen();
                setActionItemIdToBeDeleted(params.row.id);
              }}
            >
              <DeleteIcon className={icon_style} />
            </Typography>
          )}
        </Box>
      ),
    },
  ];

  const updateAlert = (error, type) => {
    setAlert(error);
    setAlertType(type);
  };

  return (
    <Box className={container}>
      {alert.length > 0 ? (
        <Box mb={2}>
          <Alert
            message={alert}
            open={alert.length > 0}
            severity={alertType}
            onClose={() => {
              updateAlert("", "");
              resetActionAlertRequest();
            }}
          />
        </Box>
      ) : null}
      <Box className={filter_row} mb={2}>
        <DateDropdown
          handleDateChange={handleDateChange}
          selectedDate={selectedDate}
        />
        <Box pr={2}>
          <ActionButton
            // disabled={
            //   selectedDate !== getDateValue(getMonthEndDateOrUpcomingFriday())
            // }
            isAlternateButton={true}
            buttonText={"+  Add Action Item"}
            onClick={handleAddActionItemOpen}
          />
        </Box>
        <ActionButton buttonText={"Filter"} onClick={handleOpen} />
      </Box>
      <DataGrid
        loading={actionItems.loading}
        className="data-grid"
        rows={actionItems.items}
        columnHeaderHeight={35}
        density="comfortable"
        columns={columns}
        disableExport={true}
        disableReorder={true}
        filterable={false}
        resizable={false}
        disableColumnMenu={true}
        rowSelection={false}
        slots={{
          loadingOverlay: () => (
            <Stack className={grid_loader}>
              <Loader />
            </Stack>
          ),
          noRowsOverlay: () => (
            <Stack className={no_rows_text}>No Action Items</Stack>
          ),
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: payload.pagingLimit,
              page: payload.pagingOffset,
            },
          },
        }}
        paginationMode="server"
        rowCount={actionItems.totalItemCount}
        onPaginationModelChange={(newPage) => {
          fetchActionItems(newPage.page);
        }}
      />
      <FilterModal
        open={open}
        title="Filter Action Items"
        handleClose={handleFilterClose}
        handleSave={applyFilter}
        saveButtonText="Apply"
        content={filterContent()}
        onClearFilter={clearFilters}
      />
      {openAddModal && (
        <AddActionItemModal
          open={openAddModal}
          handleClose={handleAddActionItemClose}
          handleSave={submitAddForm}
          userList={users.users}
          disabledOptionsAssignedTo={[users.currentUser]}
          actionItemStatuses={actionItems.actionItemStatuses}
          projects={actionItems.projects}
          disableActionItemStatus={true}
          currentUser={users.currentUser}
        />
      )}
      {openDeleteModal && (
        <DeleteActionItemModal
          open={openDeleteModal}
          handleClose={handleDeleteActionItemClose}
          handleSave={deleteActionItem}
          title="Delete Action Item"
          saveButtonText="Delete"
          content="Are you sure you want to delete this action item?"
        />
      )}
      {openUpdateModal && (
        <AddActionItemModal
          open={openUpdateModal}
          handleClose={handleUpdateActionItemClose}
          handleSave={submitUpdateForm}
          userList={users.users}
          actionItemStatuses={actionItems.actionItemStatuses}
          projects={actionItems.projects}
          actionItemToBeUpdated={actionItemToBeUpdated}
          isEdit={openUpdateModal}
          currentUser={users.currentUser}
          disableProjectName={
            users.currentUser.id !== actionItemToBeUpdated.createdBy
          }
          disableAssignedTo={
            users.currentUser.id !== actionItemToBeUpdated.createdBy
          }
          disableActionItemStatus={
            users.currentUser.id !== actionItemToBeUpdated.createdBy &&
            users.currentUser.id !== actionItemToBeUpdated.assigneeUserId
          }
          disableDescription={
            users.currentUser.id !== actionItemToBeUpdated.createdBy
          }
        />
      )}
    </Box>
  );
}

export default connect(({ actionItems, users }) => ({ actionItems, users }), {
  getActionItemsRequest,
  getActionItemStatusesRequest,
  addActionItemsRequest,
  updateActionItemsRequest,
  getAllProjectsRequest,
  deleteActionItemRequest,
  resetActionAlertRequest,
})(ActionItems);
