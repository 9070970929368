import React from "react";
import {
  Drawer as MuiDrawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ListItemIcon from "@mui/material/ListItemIcon";
import { styled, useTheme } from "@mui/material/styles";
import { DrawerHeader } from "../../layout";
import { useNavigate } from "react-router-dom";

const drawerWidth = 150;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Sidebar = ({ open, drawerWidth, handleDrawerClose }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Drawer
      sx={{
        "*": { color: "white" },
        "& .MuiDrawer-paper": {
          backgroundColor: "#dd4b39",
        },
      }}
      variant="permanent"
      open={open}
    >
      <DrawerHeader sx={{ backgroundColor: "#dd4b39" }}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon sx={{ color: "#fff" }} />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <List>
        <ListItemButton className="nav-button">
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 1 : "auto",
              justifyContent: "center",
            }}
            onClick={() => navigate("/")}
          >
            <DashboardIcon />
          </ListItemIcon>

          <ListItemText
            sx={{ opacity: open ? 1 : 0 }}
            className={window.location.pathname === "/" ? "active-nav" : ""}
            primary="Dashboard"
            onClick={() => navigate("/")}
          />
        </ListItemButton>
        <ListItemButton className="nav-button">
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 1 : "auto",
            }}
            onClick={() => navigate("/projects")}
          >
            <CollectionsBookmarkIcon />
          </ListItemIcon>
          <ListItemText
            sx={{ opacity: open ? 1 : 0 }}
            className={
              window.location.pathname.includes("/projects") ? "active-nav" : ""
            }
            primary="Projects"
            onClick={() => navigate("/projects")}
          />
        </ListItemButton>
        <ListItemButton className="nav-button">
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 1 : "auto",
              justifyContent: "center",
            }}
            onClick={() => navigate("/action-items")}
          >
            <PendingActionsIcon />
          </ListItemIcon>
          <ListItemText
            sx={{ opacity: open ? 1 : 0 }}
            className={
              window.location.pathname.includes("/action-items")
                ? "active-nav"
                : ""
            }
            primary="Action Items"
            onClick={() => navigate("/action-items")}
          />
        </ListItemButton>
      </List>
    </Drawer>
  );
};

export default Sidebar;
